<template>
    <table class="table align-items-center table-custom pb-0">
        <thead class="thead-light">
            <tr>
                <th
                    v-for="(heading, index) in tableData.headings"
                    :key="index"
                    :class="index === 0 ? 'pl-5' : ''"
                >
                    <div class="d-flex" v-if="index !== 0">
                        <div class="">{{ heading }}</div>
                    </div>
                    <div v-else>
                        {{ heading }}
                    </div>
                </th>
            </tr>
        </thead>
        <tbody class="list">
            <tr v-for="(data, index) in tableData.datas" :key="index" @click="getSlipData(data.voucher)">
                <th
                    scope="row"
                    v-for="(content, index) in tableData.contents"
                    :key="index"
                    :class="index === 0 ? 'pl-5' : ''"
                >
                    <div class="media d-flex" v-if="content === 'status'">
                        <span class="mb-0 text-md">
                            <span
                                class="badge badge-success"
                                v-if="data[content] === 'in'"
                            >
                                {{
                                    $store.state.language === "en"
                                        ? `${data[content]}`
                                        : "ငွေဝင်"
                                }}
                            </span>
                            <span
                                class="badge badge-danger"
                                v-if="data[content] === 'out'"
                            >
                                {{
                                    $store.state.language === "en"
                                        ? `${data[content]}`
                                        : "ငွေထွက်"
                                }}
                            </span>
                        </span>
                    </div>

                    <div class="media align-items-center" v-else>
                        <span class="name mb-0 text-md">{{
                            (type === "unitList" && content !== "date"
                                ? Number(data[content]).toLocaleString()
                                : data[content]) || '-'
                        }}</span>
                    </div>
                </th>
            </tr>
            <tr v-if="type === 'unitList'">
                <th scope="row" class="pl-5"></th>
                <th scope="row">
                    Total -
                    <b class="text-success">{{
                        Number(totalUnitIn()).toLocaleString()
                    }}</b>
                </th>
                <th scope="row">
                    Total -
                    <b class="text-success">{{
                        Number(totalUnitOut()).toLocaleString()
                    }}</b>
                </th>
                <th></th>
            </tr>
        </tbody>
    </table>
</template>
<script>
import { DateTime } from 'luxon';

export default {
  props: ['tableData', 'type'],
  methods: {
    getSlipData(voucher) {
      if (voucher) {
        this.$emit('getSlipData', voucher);
      }
    },
    betTime(time) {
      const newTime = new Date(time);
      const targetTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const myanmarDateTime = DateTime.fromJSDate(newTime);
      const targetDateTime = myanmarDateTime.setZone(targetTimeZone);
      const adjustedDrawDate = new Date(targetDateTime.toISO()).toLocaleString('en-US', { timeZone: targetTimeZone });
      return moment(adjustedDrawDate).format('lll');
    },
    totalUnitIn() {
      let total = 0;
      for (let i = 0; i < this.tableData.datas.length; i += 1) {
        total += parseInt(this.tableData.datas[i].unit_in, 10);
      }
      return total;
    },
    totalUnitOut() {
      let total = 0;
      for (let i = 0; i < this.tableData.datas.length; i += 1) {
        total += parseInt(this.tableData.datas[i].unit_out, 10);
      }
      return total;
    },
  },
};
</script>

<style scoped>
@media (max-width: 768px) {
    .table-custom {
        display: block;
        overflow-x: auto;
        width: 100%;
        -webkit-overflow-scrolling: touch;
    }
}
</style>
