<template>
  <div style="background-image: url('https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/1c2e5ac4-c359-4940-f18c-3d029d46e100/public'); min-height: 100vh; height: auto; background-size: cover; padding-bottom: 15rem; background-attachment: fixed;">
    <!-- <img src="/build/assets/img/brand/khit_thit.jpg" alt="" /> -->
    <!-- <div class="col-12 d-flex my-0">
      <img
        class="mx-auto"
        src="https://cdn.gold549.com/build/assets/img/brand/logo.png"
        alt=""
        style="width: 120px; height: 120px; margin-top: -22px"
      />
    </div> -->
    <Sticky>
      <div class="bg-white"> 
        <NavBar />
        <div class="col-lg-12 col-12 mx-auto p-0 d-flex main-image-wrapper">
              <b-carousel
                  id="carousel-1"
                  :interval="3000"
                  controls
                  fade
                  style="width: 100%; height: 100%;"
                  v-if="!threedData.embedded_link && !threedData.isLive"
              >
                  <b-carousel-slide
                      :img-src="$t('images.banners.banner1')"
                  ></b-carousel-slide>
                  <b-carousel-slide
                      :img-src="$t('images.banners.banner2')"
                  ></b-carousel-slide>
                  <b-carousel-slide
                      :img-src="$t('images.banners.banner3')"
                  ></b-carousel-slide>
                  <b-carousel-slide
                      :img-src="$t('images.banners.banner4')"
                  ></b-carousel-slide>
                  <b-carousel-slide
                      :img-src="$t('images.banners.banner5')"
                  ></b-carousel-slide>
                  <b-carousel-slide
                      :img-src="$t('images.banners.banner6')"
                  ></b-carousel-slide>
              </b-carousel>
          </div>

        <div class="col-7 d-flex px-0" style="margin-top: -0.7rem; z-index: 10; padding-left: 10px; padding-top: 5px; padding-bottom: 5px; padding-right: 10px; border-radius: 15px; margin-left: 10px; margin-right: 3px; background: #D02126;">
          <i class="fas fa-volume-up my-auto ml-2 text-white" style="font-size: 14px; font-weight: 600;"></i>
          <NoticeBar
            class="col-lg-10 col-10 mx-0 px-0 mx-auto notice-bar"
            scrollable
            :text="websiteInfo[0]?.noticeText"
            v-if="!threedData.embedded_link && !threedData.isLive"
          />
        </div>

        <div class="my-3 text-center py-2" style="background: linear-gradient(to right, #e89b9e, #e89b9e); font-weight: 700; color: black;">
          TOP GAMES
        </div>
      </div>
    </Sticky>
    

    <div class="cards-container-mobile">
      <div class="container-fluid justify-content-end mt-1 px-0 d-flex pt-0">
        <!-- <div class="col-12"> -->
						<div class="pr-0 mx-0 pl-2 index_left sidebar" style="overflow-y: auto; margin-bottom: 25rem; height: 90vh; width: 20%;">
              <div style="height: 110vh;">
                <ul class="nav nav-pills game-nav">
                  <li class="nav-item d-flex">
                    <div class="side-nav-icon d-flex flex-column p-2" :class="sideNav === 'lottery' ? 'active': ''" style="width: 90%; height: 5rem; border-radius: 10px;">
                      <img v-show="sideNav !== 'lottery'" src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/yyr_cards/icons/2D3D%202.png" class="mx-auto p-0 m-0" style="cursor: pointer; font-size: 4rem; width: 3rem;" @click="changeSideNav('lottery');">
                      <img v-show="sideNav === 'lottery'" src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/yyr_cards/icons/2D3D.png" class="mx-auto p-0 m-0" style="cursor: pointer; font-size: 4rem; width: 3rem;" @click="changeSideNav('lottery');">
                      <span class="mt-1 mx-auto side-nav-icon-text" :class="sideNav === 'lottery' ? 'active': ''">{{$t('home.lottery')}}</span>
                    </div>
                  </li>
                  <li class="nav-item d-flex mt-2">
                    <div class="side-nav-icon d-flex flex-column p-2" :class="sideNav === 'cardGames' ? 'active': ''" style="width: 90%; height: 5rem; border-radius: 10px;">
                      <img v-show="sideNav !== 'cardGames'" src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/yyr_cards/icons/skm%202.png" class="mx-auto p-0 m-0" style="cursor: pointer; font-size: 4rem; width: 3rem;" @click="changeSideNav('cardGames');">
                      <img v-show="sideNav === 'cardGames'" src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/yyr_cards/icons/skm.png" class="mx-auto p-0 m-0" style="cursor: pointer; font-size: 4rem; width: 3rem;" @click="changeSideNav('cardGames');">
                      <span class="mt-1 mx-auto side-nav-icon-text" :class="sideNav === 'cardGames' ? 'active': ''">{{$t('home.card')}}</span>
                    </div>
                  </li>
                  <li class="nav-item d-flex mt-2">
                    <div class="side-nav-icon d-flex flex-column p-2" :class="sideNav === 'football' ? 'active': ''" style="width: 90%; height: 5rem; border-radius: 10px;">
                      <img v-show="sideNav !== 'football'" src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/yyr_cards/icons/football%202.png" class="mx-auto p-0 m-0" style="cursor: pointer; font-size: 4rem; width: 3rem;" @click="changeSideNav('football');">
                      <img v-show="sideNav === 'football'" src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/yyr_cards/icons/football.png" class="mx-auto p-0 m-0" style="cursor: pointer; font-size: 4rem; width: 3rem;" @click="changeSideNav('football');">
                      <span class="mt-1 mx-auto side-nav-icon-text" :class="sideNav === 'football' ? 'active': ''">{{$t('home.football')}}</span>
                    </div>
                  </li>
                  <li class="nav-item d-flex mt-2">
                    <div class="side-nav-icon d-flex flex-column p-2" :class="sideNav === 'slot' ? 'active': ''" style="width: 90%; height: 5rem; border-radius: 10px;">
                      <img v-show="sideNav !== 'slot'" src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/yyr_cards/icons/slot%202.png" class="mx-auto p-0 m-0" style="cursor: pointer; font-size: 4rem; width: 3rem;" @click="changeSideNav('slot');">
                      <img v-show="sideNav === 'slot'" src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/yyr_cards/icons/slot.png" class="mx-auto p-0 m-0" style="cursor: pointer; font-size: 4rem; width: 3rem;" @click="changeSideNav('slot');">
                      <span class="mt-1 mx-auto side-nav-icon-text" :class="sideNav === 'slot' ? 'active': ''">{{$t('home.slot')}}</span>
                    </div>
                  </li>
                  <li class="nav-item d-flex mt-2">
                    <div class="side-nav-icon d-flex flex-column p-2" :class="sideNav === 'fishing' ? 'active': ''" style="width: 90%; height: 5rem; border-radius: 10px;">
                      <img v-show="sideNav !== 'fishing'" src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/yyr_cards/icons/fishing%202.png" class="mx-auto p-0 m-0" style="cursor: pointer; font-size: 4rem; width: 3rem;" @click="changeSideNav('fishing');">
                      <img v-show="sideNav === 'fishing'" src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/yyr_cards/icons/fishing.png" class="mx-auto p-0 m-0" style="cursor: pointer; font-size: 4rem; width: 3rem;" @click="changeSideNav('fishing');">
                      <span class="mt-1 mx-auto side-nav-icon-text" :class="sideNav === 'fishing' ? 'active': ''">{{$t('home.fishing')}}</span>
                    </div>
                  </li>
                  <li class="nav-item d-flex mt-2">
                    <div class="side-nav-icon d-flex flex-column p-2" :class="sideNav === 'casino' ? 'active': ''" style="width: 90%; height: 5rem; border-radius: 10px;">
                      <img v-show="sideNav !== 'casino'" src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/yyr_cards/icons/casino%202.png" class="mx-auto p-0 m-0" style="cursor: pointer; font-size: 4rem; width: 3rem;" @click="changeSideNav('casino');">
                      <img v-show="sideNav === 'casino'" src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/yyr_cards/icons/casino.png" class="mx-auto p-0 m-0" style="cursor: pointer; font-size: 4rem; width: 3rem;" @click="changeSideNav('casino');">
                      <span class="mt-1 mx-auto side-nav-icon-text" :class="sideNav === 'casino' ? 'active': ''">{{$t('home.casino')}}</span>
                    </div>
                  </li>
                  <li class="nav-item d-flex mt-2">
                    <div class="side-nav-icon d-flex flex-column p-2" :class="sideNav === 'vs' ? 'active': ''" style="width: 90%; height: 5rem; border-radius: 10px;">
                      <img v-show="sideNav !== 'vs'" src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/yyr_cards/icons/vs%202.png" class="mx-auto p-0 m-0" style="cursor: pointer; font-size: 4rem; width: 3rem;" @click="changeSideNav('vs');">
                      <img v-show="sideNav === 'vs'" src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/yyr_cards/icons/vs.png" class="mx-auto p-0 m-0" style="cursor: pointer; font-size: 4rem; width: 3rem;" @click="changeSideNav('vs');">
                      <span class="mt-1 mx-auto side-nav-icon-text" :class="sideNav === 'vs' ? 'active': ''">VS</span>
                    </div>
                  </li>
                </ul>
              </div>
						</div>

						<div class="index_rightbar mr-0 pl-0 col-10 pl-3 pr-2" id="index_rightbar" style="height: 90vh; overflow-y: scroll;">

              <div :class="sideNav === 'skm' ? 'active' : ''" v-show="sideNav === 'skm'">
                <img class="mx-1 my-1" src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/0e5010c2-c803-492f-3a2e-af380f726a00/public" alt="skm" style="cursor: pointer; width: 47.5%; height: auto;" @click="$playSound(); fetchShanKoMeeData(0)">
                <img class="mx-1 my-1" src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/50b677c2-0e37-4b52-ae91-37e933c40100/public" alt="skm" style="cursor: pointer; width: 47.5%; height: auto;" @click="$playSound(); fetchShanKoMeeData(1)">
                <img class="mx-1 my-1" src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/20bc3ea4-2cc7-425e-cbec-8ce9058f0800/public" alt="skm" style="cursor: pointer; width: 47.5%; height: auto;" @click="$playSound(); fetchShanKoMeeData(2)">
                <img class="mx-1 my-1" src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/3653beaf-59ff-4e8e-21da-e63ea07cf400/public" alt="skm" style="cursor: pointer; width: 47.5%; height: auto;" @click="$playSound(); fetchShanKoMeeData(3)">
                <img class="mx-1 my-1" src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/b319c7ca-a9b0-4ea6-4bb9-48304616af00/public" alt="skm" style="cursor: pointer; width: 47.5%; height: auto;" @click="$playSound(); fetchShanKoMeeData(4)">
                <img class="mx-1 my-1" src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/67b23c4a-9f90-48bf-4c9f-68d980594000/public" alt="skm" style="cursor: pointer; width: 47.5%; height: auto;" @click="$playSound(); fetchShanKoMeeData(5)">
              </div>

              <div :class="sideNav === 'bugyi' ? 'active' : ''" v-show="sideNav === 'bugyi'">
                <img class="mx-1 my-1" src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/0e5010c2-c803-492f-3a2e-af380f726a00/public" alt="skm" style="cursor: pointer; width: 47.5%; height: auto;" @click="$playSound(); fetchBugyee(0)">
                <img class="mx-1 my-1" src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/50b677c2-0e37-4b52-ae91-37e933c40100/public" alt="skm" style="cursor: pointer; width: 47.5%; height: auto;" @click="$playSound(); fetchBugyee(1)">
                <img class="mx-1 my-1" src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/20bc3ea4-2cc7-425e-cbec-8ce9058f0800/public" alt="skm" style="cursor: pointer; width: 47.5%; height: auto;" @click="$playSound(); fetchBugyee(2)">
                <img class="mx-1 my-1" src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/3653beaf-59ff-4e8e-21da-e63ea07cf400/public" alt="skm" style="cursor: pointer; width: 47.5%; height: auto;" @click="$playSound(); fetchBugyee(3)">
                <img class="mx-1 my-1" src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/b319c7ca-a9b0-4ea6-4bb9-48304616af00/public" alt="skm" style="cursor: pointer; width: 47.5%; height: auto;" @click="$playSound(); fetchBugyee(4)">
                <img class="mx-1 my-1" src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/67b23c4a-9f90-48bf-4c9f-68d980594000/public" alt="skm" style="cursor: pointer; width: 47.5%; height: auto;" @click="$playSound(); fetchBugyee(5)">
              </div>
              
              <div style="height: 500vh;" v-if="sideNav !== 'skm' && sideNav !== 'bugyi'">
                <div class="tab-content game px-2 " style="background-color: rgb(245, 192, 194, .5); min-height: 50vh; border-radius: 20px; height: auto; padding-top: 0.7rem; margin-bottom: 2rem;">
                  
                  <div class="lottery">
                    <div class="d-flex pt-2 pl-2">
                      <img style="width: auto; height: 35px;" src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/yyr_cards/icons/2D%203D%20Icon.png" alt="">
                      <div class="pl-2 py-0 my-auto font-weight-450 text-dark" style="font-size: 1.2rem;">{{ $t("2d") }} / {{ $t("3d") }}</div>
                    </div>

                    <div class="mt-3" :class="sideNav === 'lottery' ? 'active' : ''">
                      <div class="gwrap_1 pb-3">
                        <img src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/yyr_cards/en/2D.png" alt="slot" style="cursor: pointer; width: 100%; height: auto;" @click="$playSound(); $router.push('/user/2d')">
                        <img src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/yyr_cards/en/3D.png" alt="slot" style="cursor: pointer; width: 100%; height: auto; margin-top: 0.1rem;" @click="$playSound(); $router.push('/user/3d')">
                        <img :src="$t('images.cards.2dLive')" alt="slot" style="cursor: pointer; width: 100%; height: auto; " @click="$playSound(); $router.push('/user/2d/home')">
                        <img :src="$t('images.cards.3dLive')" alt="slot" :class="$i18n.locale !== 'mm' ? 'ml-1':''" style="width: 99%; height: auto;" @click="$playSound(); $router.push('/user/3d/home')">
                      </div>
                    </div>
                  </div>
                  
                  <hr>
                  <div class="cardGames">
                    <div class="d-flex pt-3 pl-2">
                      <img style="width: auto; height: 35px;" src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/yyr_cards/icons/Poker%20Card%20Icon.png" alt="">
                      <div class="pl-2 py-0 my-auto font-weight-450 text-dark" style="font-size: 1.2rem;">{{$t('home.card_game')}}</div>
                    </div>
                    
                    <div class="pb-3 -mt-2" :class="sideNav === 'cardGames' ? 'active' : ''">
                        <img :src="$t('images.cards.skm')" alt="slot" style="cursor: pointer; width: 99%; margin-left: 0.3rem; height: auto; " @click="$playSound(); changeSideNav('skm')">
                        <img :src="$t('images.cards.bugyi')" alt="slot" style="cursor: pointer; width: 99%; height: auto; margin-top: -2.7rem;" @click="$playSound(); changeSideNav('bugyi')">
                        <!-- <img src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/4d535be5-c439-4aac-d25a-cbf2384d5a00/public" alt="slot" style="cursor: pointer; width: 102%; height: auto; margin-left: -0.2rem; margin-top: -1.3rem;" @click="$playSound(); fetchBetGame('abcd', 'yoeyar-abcd')">
                        <img src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/1d8f5dc6-b3c8-4d4c-9cec-0c7860dbca00/public" alt="slot" style="cursor: pointer; width: 101%; height: auto; " @click="$playSound(); fetchBetGame('tiger-dragon', 'yoeyar-dragon-tiger')"> -->
                    </div>
                  </div>
                  

                  <hr>
                  <div class="football">
                    <div class="d-flex pt-3 pl-2">
                      <img style="width: auto; height: 35px;" src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/yyr_cards/icons/Football%20Icon.png" alt="">
                      <div class="py-0 my-auto font-weight-450 text-dark" style="font-size: 1.2rem;">{{$t('home.football')}}</div>
                    </div>

                    <div class="mx-1" :class="sideNav === 'football' ? 'active' : ''">
                        <img :src="$t('images.cards.body')" alt="slot" style="cursor: pointer; width: 100%; height: auto; margin-top: -1rem;" @click="$playSound(); $router.push('/body-fb')">
                        <img :src="$t('images.cards.maung')" alt="slot" style="cursor: pointer; width: 100%; height: auto; margin-top: -3rem;" @click="$playSound(); $router.push('/maung-fb')">
                        <!-- <img src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/e5d5d232-3e64-422e-5449-943ecc626200/public" alt="slot" style="cursor: pointer; width: 100%; height: auto; margin-top: -3rem;"> -->
                        <!-- <img src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/bda41bfd-d7f6-4d51-d1b4-7b088bb69e00/public" alt="slot" style="cursor: pointer; width: 100%; height: auto; margin-top: -2rem;" @click="$playSound(); $router.push('/live')"> -->
                        <img :src="$t('images.cards.fbLive')" alt="slot" style="cursor: pointer; width: 100%; height: auto; margin-top: -3.5rem;" @click="$playSound(); $router.push('/live')">
                    </div>
                  </div>

                  <hr>
                  <div class="slot">
                    <div class="d-flex pt-3 pl-2 ">
                      <img style="width: auto; height: 35px;" src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/yyr_cards/icons/Slot%20Icon.png" alt="">
                      <div class="pl-2 py-0 my-auto font-weight-450 text-dark" style="font-size: 1.2rem;">{{$t('home.slot_game')}}</div>
                    </div>

                    <div class="my-3 mb-5" :class="sideNav === 'slot' ? 'active' : ''">
                      <img src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/108c0373-fb77-4770-320b-811e4ec18700/public" alt="skm" style="cursor: pointer; width: 50%; height: auto;" @click="$playSound(); $router.push('/user/slots?provider=Pragmatic')">
                      <img src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/9b119594-83c5-45a2-fbcd-3bbfd18e4700/public" alt="skm" style="cursor: pointer; width: 50%; height: auto;" @click="$playSound(); $router.push('/user/slots?provider=Jili')">
                      <img src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/83f5e10b-79bf-46aa-ccdb-800a4aef3100/public" alt="skm" style="cursor: pointer; width: 50%; height: auto;" @click="$playSound(); $router.push('/user/slots?provider=5G')">
                      <img src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/af94115e-9812-4b43-b12f-5d8f2a40bf00/public" alt="skm" style="cursor: pointer; width: 50%; height: auto;" @click="$playSound(); $router.push('/user/slots?provider=JDB')">
                      <img src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/e6203699-3213-44f4-c9db-02b7f89b0f00/public" alt="skm" style="cursor: pointer; width: 50%; height: auto;" @click="$playSound(); $router.push('/user/slots?provider=FaChai')">
                      <img src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/80a5d5e1-d768-441c-5ba2-6535f2ea4800/public" alt="skm" style="cursor: pointer; width: 50%; height: auto;" @click="$playSound(); $router.push('/user/slots?provider=JOKER')">
                      <img src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/91fb9c94-b5af-4f43-3867-b45090b10d00/public" alt="skm" style="cursor: pointer; width: 50%; height: auto;" @click="$playSound(); $router.push('/user/slots?provider=PGSoft')">
                    </div>
                  </div>
                  

                  <hr>
                  <div class="fishing">
                    <div class="d-flex pt-3 pl-2">
                      <img style="width: auto; height: 35px;" src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/yyr_cards/icons/Fishing%20Slot%20Icon.png" alt="">
                      <div class="pl-2 py-0 my-auto font-weight-450 text-dark" style="font-size: 1.2rem;">{{$t('home.fishing_game')}}</div>
                    </div>

                    <div class="pb-3 mt-2" :class="sideNav === 'fishing' ? 'active' : ''">
                      <img src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/0c7009e2-aa9d-436e-5ec4-4160602e5200/public" alt="skm" style="cursor: pointer; width: 50%; height: auto;"  @click="$playSound(); $router.push('/user/fishing?provider=Jili')">
                      <img src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/dbe9746b-f477-44ee-e436-97c5cee1c400/public" class="mt-3" alt="skm" style="cursor: pointer; width: 50%; height: auto;" @click="$playSound(); $router.push('/user/fishing?provider=JDB')">
                    </div>
                  </div>

                  <hr>
                  <div class="casino">
                    <div class="d-flex pt-3 pl-2 ">
                      <img style="width: auto; height: 35px;" src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/yyr_cards/icons/Casino%20Icon.png" alt="">
                      <div class="pl-2 py-0 my-auto font-weight-450 text-dark" style="font-size: 1.2rem;">{{$t('home.live_casino')}}</div>
                    </div>

                    <div class="mx-1 pb-2" :class="sideNav === 'casino' ? 'active' : ''">
                      <img src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/51c24a1d-451b-4dc0-db76-69a43bd00400/public" alt="casino" style="cursor: pointer; width: 100%; height: auto; margin-top: -0.2rem;" @click="$playSound(); gameInit('PP Live')">
                      <img src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/fb177a01-62ba-47c2-b58b-9df8a178d900/public" alt="casino" style="cursor: pointer; width: 100%; height: auto; margin-top: -2.4rem;" @click="$playSound(); gameInit('WM')">
                      <img src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/d7e037ce-626c-48cb-cfd5-0ffbcf75ff00/public" alt="casino" style="cursor: pointer; width: 100%; height: auto; margin-top: 0.7rem;" @click="$playSound(); gameInit('Sexy')">
                      <img src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/defa98b6-643e-499a-f4de-c9280c7e5200/public" alt="casino" style="cursor: pointer; width: 100%; height: auto; margin-top: -2.5rem;" @click="$playSound(); showAlertBox('BG')">
                    </div>
                              <div class="col-4">
                                <div
                                    class="modal fade"
                                    id="limit"
                                    tabindex="-1"
                                    role="dialog"
                                    aria-labelledby="modal-form"
                                    @click="hideLimit()"
                                >
                                    <div
                                        class="modal-dialog modal- modal-dialog-centered"
                                        role="document"
                                    >
                                        <div class="modal-content">
                                            <div class="modal-body my-5 mx-3 text-center">
                                            <span class="text-danger text-lg">
                                                <b>အထူးသတိပြုရန်။</b> <br><br>
                                            </span>
                                            <span>{{limitText}}</span>
                                            <br><br> <span class="text-dark text-sm">
                                                <button class="btn btn-danger" @click="gameInit(desiredGame)">
                                                {{
                                                    $store.state.language === "en"
                                                        ? "Play The Game"
                                                        : "ဂိမ်းဆော့မည်။"
                                                }}
                                                </button>
                                            </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                  </div>
                  <hr>
                  <div class="vs pb-4">
                    <div class="d-flex pt-3 pl-2">
                      <img style="width: auto; height: 35px;" src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/yyr_cards/icons/Casino%20Icon.png" alt="">
                      <div class="pl-2 py-0 my-auto font-weight-450 text-dark" style="font-size: 1.2rem;">VS</div>
                    </div>
                    <img src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/723ca88a-6cf0-406b-d7b3-a93d023f6f00/public" alt="slot" style="cursor: pointer; width: 103%; margin-left: -0.4rem; height: auto;" @click="$playSound(); gameInit('CockFight')">
                    <!-- <img src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/72ba5bc4-dfbb-4606-c7ca-8d8d93d8dd00/public" alt="slot" style="cursor: pointer; width: 99%; height: auto; margin-top: -0.8rem; margin-left: 0.2rem;" @click="$playSound(); fetchBetGame('horse', 'yoeyar-horse')"> -->
                  </div>

                </div>
              </div>
						</div>
					<!-- </div> -->
      </div>
    </div>

    <van-overlay :show="isLoading">
        <div class="wrapper" @click.stop>
          <van-loading type="spinner" color="#ffffff" class="mx-auto"/>
        </div>
    </van-overlay>

    <div class="col-4">
            <div
                class="modal fade"
                id="limit"
                tabindex="-1"
                role="dialog"
                aria-labelledby="modal-form"
                @click="hideLimit()"
            >
                <div
                    class="modal-dialog modal- modal-dialog-centered"
                    role="document"
                >
                    <div class="modal-content">
                        <div class="modal-body my-5 mx-3 text-center">
                         <span class="text-danger text-lg">
                            <b>{{$t('skm.title')}}</b> <br><br>
                         </span>

                        <span>{{limitText}}</span>
                        <br><br> <span class="text-dark text-sm">
                            <button class="btn btn-danger" @click="hideLimit()">
                            {{$t('okay')}}
                            </button>
                        </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

  </div>
</template>
<script>
import NavBar from '@/layouts/NavBar.vue';
import { NoticeBar, Dialog, Toast, Sticky } from 'vant';
import { mapActions, mapState } from 'vuex';

export default {
  components: { NoticeBar, Sticky },
  data() {
    return {
      isLoading: false,
      twoDdata: {},
      twoDHistories: {},
      threeDHistories: {},
      threedData: {},
      websiteInfo: {},
      isLogged: false,
      // sideNav: 'lottery'
      limitText: null,
      desiredGame: null,
    };
  },
  computed: {
    ...mapState(['sideNav']),
  },

  methods: {
    hideLimit() {
      $('#limit').modal('hide');
    },

    showAlertBox(game) {
      if(game === 'BG') this.limitText = 'BG Live Casino တွင် 1K unit သည် 1,000 ကျပ်နှင့်ညီမျပါသည်။';
      $('#limit').modal('show');
      this.desiredGame = game;
    },

    getCountry() {
      fetch('https://ipinfo.io/json')
        .then(response => response.json())
        .then(data => {
          // this.country = data.country;
          console.log(data)
        })
        .catch(error => console.error('Error fetching country data:', error));
    },
    hideLimit() {
        $('#limit').modal('hide');
    },
    
    changeSideNav(para) {
      // this.sideNav = para;
      console.log('para', para)
      this.$store.commit('updateSideNav', para);
      this.scrollToElement(para);
    },

    scrollToElement(className) {
      const container = this.$el.querySelector('.index_rightbar');
      const targetElement = container.querySelector('.' + className);

      // Scroll to the target element within the container
      if (container && targetElement) {
        let top = targetElement.offsetTop;
        if(className === 'lottery'){
          top -= 20;
        }
        container.scrollTo({
          top,
          behavior: 'smooth'
        });
      }
    },

    scrollMeTo(refName) {
      document.getElementById('index_rightbar').scrollTo({
        top: 0,
        behavior: 'smooth'  // This creates the smooth scrolling effect
      })
    },

    async fetchBetGame(game = null, link = null) {
      try {
        this.isLoading = true;
        
        if(game){
            this.gameName = game
            this.gameLink = link
        }
            const res = await axios.get(`/${this.gameName}-data`, {
            params: {
                    id : this.$store.state.authUser.name,
                    balance : this.$store.state.authUser.amount,
                    info : {
                            nickname : this.$store.state.authUser.user_name,
                            profile : 3
                        }
                    }
            });
            window.location.assign(`https://${link}.vercel.app/?id=${this.$store.state.authUser.name}&passcode=${res.data.passcode}&exit=https%3A%2F%2F${window.location.host}/home`)
            this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },

    async fetchBugyee(level = null) {
      try {
        this.isLoading = true;
        const authUser = await this.fetchUser();
        if (!authUser) {
          this.$router.push('/auth/login');
        }
        
        const amount = authUser.amount;
        if(level == 0 && amount < 1000){
            this.limitText = "1,000 အောက်ရောက်နေပါသည်။ ငွေထပ်မံဖြည့်သွင်းပြီးမှ သည်အခန်းကို ဆော့လို့ရပါမည်။";
            $('#limit').modal('show');
            this.isLoading = false;
            return false;
        }
        if(level == 1 && amount < 3000){
            this.limitText = "3,000 အောက်ရောက်နေပါသည်။ ငွေထပ်မံဖြည့်သွင်းပြီးမှ သည်အခန်းကို ဆော့လို့ရပါမည်။";
            $('#limit').modal('show');
            this.isLoading = false;
            return false;
        }
        if(level == 2 && amount < 5000){
            this.limitText = "5,000 အောက်ရောက်နေပါသည်။ ငွေထပ်မံဖြည့်သွင်းပြီးမှ သည်အခန်းကို ဆော့လို့ရပါမည်။";
            $('#limit').modal('show');
            this.isLoading = false;
            return false;
        }
        if(level == 3 && amount < 10000){
            this.limitText = "10,000 အောက်ရောက်နေပါသည်။ ငွေထပ်မံဖြည့်သွင်းပြီးမှ သည်အခန်းကို ဆော့လို့ရပါမည်။";
            $('#limit').modal('show');
            this.isLoading = false;
            return false;
        }
        if(level == 4 && amount < 30000){
            this.limitText = "30,000 အောက်ရောက်နေပါသည်။ ငွေထပ်မံဖြည့်သွင်းပြီးမှ သည်အခန်းကို ဆော့လို့ရပါမည်။";
            $('#limit').modal('show');
            this.isLoading = false;
            return false;
        }
        if(level == 5 && amount < 50000){
            this.limitText = "50,000 အောက်ရောက်နေပါသည်။ ငွေထပ်မံဖြည့်သွင်းပြီးမှ သည်အခန်းကို ဆော့လို့ရပါမည်။";
            $('#limit').modal('show');
            this.isLoading = false;
            return false;
        }
            const res = await axios.get('/bugyee-new-data', {
            params: {
                    id : this.$store.state.authUser.name,
                    level,
                    balance : this.$store.state.authUser.amount,
                    info : {
                            nickname : this.$store.state.authUser.user_name,
                            profile : 3
                        }
                    },
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
            });
            // window.location.assign(`https://boogyi.yy24.bet/?id=${this.$store.state.authUser.name}&passcode=${res.data.passcode}&exit=https%3A%2F%2F${window.location.host}/home`)
            this.$store.commit('setIframeURL', `https://boogyi.yy24.bet/?id=${this.$store.state.authUser.name}&passcode=${res.data.passcode}&exit=https%3A%2F%2F${window.location.host}/home`);
            this.$store.commit('setIsCardGame', true);
            this.$router.push('/iframeURL');
            this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },

    async fetchShanKoMeeData(level = null) {
      try {
        this.isLoading = true;
        const authUser = await this.fetchUser();
        if (!authUser) {
          this.$router.push('/auth/login');
        }
        
        const amount = authUser.amount;
        if(level == 0 && amount < 1000){
            this.limitText = "1,000 အောက်ရောက်နေပါသည်။ ငွေထပ်မံဖြည့်သွင်းပြီးမှ သည်အခန်းကို ဆော့လို့ရပါမည်။";
            $('#limit').modal('show');
            this.isLoading = false;
            return false;
        }
        if(level == 0 && amount > 100000){
            this.limitText = "သတ်မှတ်ထားသော ငွေပမာဏထပ် ကျော်လွန်နေပါသည်။ သင့်လျော်သောအခန်းတွင်သာ ကစားပေးပါ။";
            $('#limit').modal('show');
            this.isLoading = false;
            return false;
        }

        if(level == 1 && amount < 3000){
            this.limitText = "3,000 အောက်ရောက်နေပါသည်။ ငွေထပ်မံဖြည့်သွင်းပြီးမှ သည်အခန်းကို ဆော့လို့ရပါမည်။";
            $('#limit').modal('show');
            this.isLoading = false;
            return false;
        }
        if(level == 1 && amount > 300000){
            this.limitText = "သတ်မှတ်ထားသော ငွေပမာဏထပ် ကျော်လွန်နေပါသည်။ သင့်လျော်သောအခန်းတွင်သာ ကစားပေးပါ။";
            $('#limit').modal('show');
            this.isLoading = false;
            return false;
        }

        if(level == 2 && amount < 5000){
            this.limitText = "5,000 အောက်ရောက်နေပါသည်။ ငွေထပ်မံဖြည့်သွင်းပြီးမှ သည်အခန်းကို ဆော့လို့ရပါမည်။";
            $('#limit').modal('show');
            this.isLoading = false;
            return false;
        }
        if(level == 2 && amount > 500000){
            this.limitText = "သတ်မှတ်ထားသော ငွေပမာဏထပ် ကျော်လွန်နေပါသည်။ သင့်လျော်သောအခန်းတွင်သာ ကစားပေးပါ။";
            $('#limit').modal('show');
            this.isLoading = false;
            return false;
        }
        
        if(level == 3 && amount < 10000){
            this.limitText = "10,000 အောက်ရောက်နေပါသည်။ ငွေထပ်မံဖြည့်သွင်းပြီးမှ သည်အခန်းကို ဆော့လို့ရပါမည်။";
            $('#limit').modal('show');
            this.isLoading = false;
            return false;
        }
        if(level == 4 && amount < 30000){
            this.limitText = "30,000 အောက်ရောက်နေပါသည်။ ငွေထပ်မံဖြည့်သွင်းပြီးမှ သည်အခန်းကို ဆော့လို့ရပါမည်။";
            $('#limit').modal('show');
            this.isLoading = false;
            return false;
        }
        if(level == 5 && amount < 50000){
            this.limitText = "50,000 အောက်ရောက်နေပါသည်။ ငွေထပ်မံဖြည့်သွင်းပြီးမှ သည်အခန်းကို ဆော့လို့ရပါမည်။";
            $('#limit').modal('show');
            this.isLoading = false;
            return false;
        }

        // if(this.nickname && this.nickname !== ''){
            const res = await axios.get('/shankomee-data', {
            params: {
                    id : this.$store.state.authUser.name,
                    level,
                    balance : this.$store.state.authUser.amount,
                    info : {
                            nickname : this.$store.state.authUser.user_name,
                            profile : 3
                        }
                    },
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
            });
            // window.location.assign(`https://skm.yy24.bet?id=${this.$store.state.authUser.name}&passcode=${res.data.passcode}&exit=https%3A%2F%2F${window.location.host}/home`)
            this.$store.commit('setIframeURL', `https://skm.yy24.bet?id=${this.$store.state.authUser.name}&passcode=${res.data.passcode}&exit=https%3A%2F%2F${window.location.host}/home`);
            this.$store.commit('setIsCardGame', true);
            this.$router.push('/iframeURL');
            this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },

    async gameInit(game) {
    try {
      this.isLoading = true;
      const authUser = await this.fetchUser();
      if (!authUser) {
        this.$router.push('/auth/login');
      }
      
      const res = await axios.get('/games/url', {
        params: {
          provider: game
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      
      // window.open(res.data.data.gameUrl, "_self");
      this.$store.commit('setIframeURL', res.data.data.gameUrl);
      this.$store.commit('setIsCardGame', false);
      this.$router.push('/iframeURL');
      
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      // this.$router.go();
    }
  },
    ...mapActions(['logoutUser', 'fetchUser']),
    async redirectRoute(path, query = null) {
      try {
        console.log('query', query)
        if(query) {
          this.$router.push({ path, query });
        } else {
          this.$router.push(path);
        }
      } catch (error) {
        console.log(error);
      }
    },

    logout() {
      Dialog.confirm({
        title:
          this.$store.state.language === 'en'
            ? 'Are you sure to log out?'
            : 'ထွက်မှာ သေချာပါသလား?',
        cancelButtonText:
          this.$store.state.language === 'en' ? 'Cancel' : 'မထွက်တော့ပါ',
        confirmButtonText:
          this.$store.state.language === 'en' ? 'Sure' : 'သေချာပြီ',
      })
        .then(async () => {
          await this.logoutUser();
          this.$router.push('/auth/login');
          Toast.success(
            this.$store.state.language === 'en' ? 'Logged out!' : 'ထွက်ပြီးပြီ'
          );
        })
        .catch(() => {});
    },
    async redirectRoute(path, query = null) {
      try {
        if (path === 'tip') {
          const text = this.$store.state.language === 'en'
            ? 'This feature is not support yet.'
            : 'ယခုအချိန်တွင် အသုံးပြုလို့မရနိုင်သေးပါ။';
          Dialog.alert({
            message: text,
            confirmButtonText:
                    this.$store.state.language === 'en'
                      ? 'Okay'
                      : 'Okay',
          }).then(() => {
          // on close
          });
        } else if(query) {
          this.$router.push({ path, query });
        } else {
          this.$router.push(path);
        }
      } catch (error) {
        console.log(error);
      }
    },
    threedDrawDateFormat(date) {
      return moment(date).format('ll');
    },
    async fetchTwoDdata() {
      try {
        const res = await axios.get('/2d/data');
        this.isLive = res.data.data[0].isLive;
      } catch (error) {
        console.log(error);
      }
    },
    async fetchWebsiteData() {
      try {
        const res = await axios.get('/website-infos');
        this.websiteInfo = res.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async fetchThreedDdata() {
      try {
        const res = await axios.get('/3d/data');
        this.threedData = res.data.data[0];
      } catch (error) {
        console.log(error);
      }
    },
    async fetchTwoThreeDHistories() {
      try {
        const twoDres = await axios.get('/twod_history');
        const threeDres = await axios.get('/threed_history');
        this.twoDHistories = twoDres.data.data;
        this.threeDHistories = threeDres.data.data;
      } catch (error) {
        console.log(error);
      }
    },

    observeMultipleElements(classNames) {
      // Iterate over each class name and observe the corresponding element
      classNames.forEach((className) => {
        // Create an IntersectionObserver for each element
        const observer = new IntersectionObserver((entries) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              this.onElementInView(className); // Call function for the specific class in view
            }
          });
        }, {
          threshold: 0.5 // Trigger when 10% of the target div is visible
        });

        // Find the element with the current class name and start observing it
        const target = this.$el.querySelector(`.${className}`);
        if (target) {
          observer.observe(target);
        }
      });
    },
    onElementInView(className) {
      this.$store.commit('updateSideNav', className);
    },
  },
  async mounted() {
    // this.getCountry();
    const authUser = await this.fetchUser();
    if (authUser) {
      this.isLogged = true;
    }
    this.fetchWebsiteData();
    this.scrollToElement(this.$store.state.sideNav);
    this.observeMultipleElements(['fishing', 'casino', 'football', 'slot', 'lottery', 'cardGames', 'vs']);
  },
};
</script>
<style scoped>
.side-nav-icon-text {
  color: #cd1e22;
  font-size: 0.9rem;
}

.side-nav-icon {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  color: #fff;
  background: linear-gradient(0deg, #F3AEB1, #FDF1F1);
  box-shadow: rgba(40, 40, 40, 0.4) 0 2px 4px, rgba(40, 40, 40, 0.4) 0 7px 13px -3px, #343434 0 -3px 0 inset;
}

.side-nav-icon.active {
  background: #cd1e22;
}
.side-nav-icon-text.active {
  color: #fff !important;
}

.sidebar {
    position: fixed;
    /* top: 23.5rem; */
    left: 0;
    height: 55vh;
    padding-bottom: 50px;
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
    overflow-y: auto;
}
.index_rightbar {
    padding: 0px 10px;
    overflow-y: scroll;
    height: 55vh;
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.detail-button {
  appearance: none;
  background-color: transparent;
  border: 2px solid #714DC7;
  text-transform: uppercase;
  border-radius: 10px;
  box-sizing: border-box;
  color: #714DC7;
  cursor: pointer;
  display: inline-block;
  font-family: Roobert,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-size: 0.9rem;
  font-weight: 450;
  line-height: normal;
  margin: 0;
  min-height: 15px;
  min-width: 0;
  outline: none;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 100%;
  will-change: transform;
}

.detail-button:disabled {
  pointer-events: none;
}

.detail-button:hover {
  color: #fff;
  background-color: #714DC7;
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
}

.detail-button:active {
  box-shadow: none;
  transform: translateY(0);
}

.nav-pills.game-nav {
    padding-right: 5px;
  }
  .nav-pills .nav-link {
    border-radius: 10px !important;
    margin-bottom: 5px;
    /* padding: 10px; */
    font-size: 10px !important;
    text-align: center;
    color: #ffffff;
    /* background: linear-gradient(#aa9ace, #714DC7); */
  }
  .nav-pills:not(.nav-pills-circle) .nav-item {
        padding-right: 0;
        margin-bottom: 0;
        width: 80px;
    }

  .nav-pills .nav-link img {
    width: 18px !important;
    /* margin-right: 10px; */
    padding-bottom: 4px;
  }
  .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    color: #fff !important;
        background: linear-gradient(#fff, #8a69d9);
}

.nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.tab-content.game a img {
    /* box-shadow: 0px 3px 14px -5px rgba(0, 0, 0, 0.4); */
    border-radius: 21px;
    margin-bottom: 10px;
    width: 100%;
}
.tab-content.game {
    padding-left: 3px;
}
.tab-content>.tab-pane {
    display: none;
    padding-right: 3px;
}
.tab-content>.active {
    display: block;
}


.css-38745a {
    margin: 0px;
    line-height: 1.5;
    font-size: 12px;
    font-family: NotoSansMyanmar;
    font-weight: 500;
}
.css-17h40hp {
    margin: 0px;
    line-height: 1.5;
    font-size: 14px;
    font-family: NotoSansMyanmar;
    font-weight: 700 !important;
}
.card {
  cursor: pointer;
  border-radius: 10px !important;
}
.main-image-wrapper img {
  width: 100%;
}
.video-container {
  height: 500px;
  width: 100%;
}
.notice-bar {
  /* margin-top: -20px; */
  z-index: 1;
  font-size: 20px;
  color: #172b4c;
  background: linear-gradient(#aa9ace, #714DC7);
}
.twod-threed-cards {
  height: 200px;
}
.live-number {
  font-weight: bold;
  font-size: 25px;
}
.live-result {
  font-weight: bolder;
  font-size: 60px;
  animation-name: live-number-animation;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}
.not-live-text {
  font-weight: bold;
  font-size: 24px;
}
.cards-container {
  display: flex;
  flex-wrap: wrap;
}
.card-body {
  padding: 0px !important;
}
.cards-container-mobile {
  display: none;
}
@keyframes live-number-animation {
  from {
    color: white;
  }
  to {
    color: red;
  }
}

@media (max-width: 768px) {
  /* .middle-container {
    padding-top: 0.1rem !important;
  } */
  hr {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .video-container {
    height: 300px;
    width: 100%;
  }
  .main-wrapper {
    padding: 7px !important;
  }
  .notice-bar {
    margin-top: 0px;
    height: 25px;
    z-index: 1;
    font-size: 12px;
    color: #ffffff;
    background: #D02126;
    border-radius: 7px;
  }
  .live-number {
    font-weight: bold;
    font-size: 20px;
  }
  .live-result {
    font-weight: bolder;
    font-size: 37px;
  }

  .cards-container {
    display: none;
  }
  .cards-container-mobile {
    padding: 0px !important;
    margin-top: 5px !important;
    display: flex;
    flex-wrap: wrap;
  }
  .home-cards {
    padding-left: 10px;
    padding-right: 10px;
  }
  .card-body {
    padding: 0px !important;
  }
  .not-live-text {
    font-size: 18px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .account-container-wrapper {
    width: auto;
    height: auto;
    border-radius: 10px;
    padding-left: 4px;
    padding-right: 4px;
    margin-top: 10px !important;
    margin-left: 10px; 
    margin-right: 10px;
    color: #000000;
    /* background: linear-gradient(#aa9ace, #714DC7); */
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  }
  .top-wrapper,
  .bottom-wrapper {
    padding: 10px;
    width: 100%;
    display: flex;
  }
  .top-right,
  .bottom-right {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: end;
  }
  .top-left,
  .bottom-left {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: start;
  }
}
</style>
