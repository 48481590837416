<template>
    <div class="app">
        <router-view></router-view>
    </div>
</template>
<script setup>
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyDrWuprC_QGHeUi7Gl641CbXyuYFYPv-E8",
  authDomain: "yy24-8e23e.firebaseapp.com",
  projectId: "yy24-8e23e",
  storageBucket: "yy24-8e23e.appspot.com",
  messagingSenderId: "171905869513",
  appId: "1:171905869513:web:b81f3f6e5b30825a684f18",
  measurementId: "G-QZGP5LQJGQ"
};


const app = initializeApp(firebaseConfig);

const messaging = getMessaging();
onMessage(messaging, (payload) => {
  console.log('Message received. ', payload);
});

getToken(messaging, { vapidKey: 'BMt-SWkmOUJk4E0IUBIcQ4x4qOruebbgDbiGts-JniE97iDUl4MMxgkEXTTp-ZMVniTKfEINC9n8APEmDmEDrok' }).then(async (currentToken) => {
  if (currentToken) {
    localStorage.setItem('fcm', currentToken);
  } else {
    console.log('No registration token available. Request permission to generate one.');
  }
}).catch((err) => {
  console.log('An error occurred while retrieving token. ', err);
});
</script>
