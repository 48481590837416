import { render, staticRenderFns } from "./Game.vue?vue&type=template&id=7a1560ff&scoped=true"
import script from "./Game.vue?vue&type=script&lang=js"
export * from "./Game.vue?vue&type=script&lang=js"
import style0 from "./Game.vue?vue&type=style&index=0&id=7a1560ff&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a1560ff",
  null
  
)

export default component.exports