<template>
    <div class="card mb-2 number-input">
        <div class="card-header font-weight-bold text-center">
            {{ $store.state.language === "en" ? "Normal" : "ရိုးရိုးထိုးမည်" }}
        </div>
        <div class="card-body col-12 d-flex number-card-body flex-wrap pb-0">
            <div class="col-6 p-0 pr-2 form-group mb-0">
                <span class="text-xs">{{ $store.state.language === "en" ? "Number" : "ဂဏန်း" }}</span>
                <TwoDSelect
                    type="number"
                    :data="selected_data"
                    field="number"
                    :numbers="allNumbers"
                />
                <span class="text-danger">{{ errors.number }}</span>
            </div>

            <div class="col-6 pr-2 p-0">
                <span class="text-xs">{{ $store.state.language === "en" ? "Amount" : "ငွေပမာဏ" }}</span>
                <div class="form-group mb-0">
                    <div class="input-group">
                        <input
                            type="number"
                            ref="amount"
                            class="form-control amount"
                            v-model="selected_data['amount']"
                            placeholder="100.00 MMK"
                            inputmode="decimal"
                            style="height: 30px !important"
                            v-on:keyup.enter="setToPreviewVoucher()"
                        />
                    </div>
                    <span class="text-danger">{{ errors.amount }}</span>
                </div>
            </div>

            <!-- <div class="col-6 pl-0 pt-2 form-group mb-0" v-if="$route.path.includes('2d')">
                <span class="text-xs">{{ $store.state.language === "en" ? "Draw Time" : "ထိုးချိန်" }}</span>
                <v-select
                    v-model="drawTime"
                    :reduce="(draw) => draw.data"
                    label="label"
                    :options="drawOptionGenerator()"
                ></v-select>
                <span class="text-danger">{{ errors.drawError }}</span>
            </div> -->

            <!-- <div class="d-flex justify-content-end pt-3 pr-0" v-if="$route.path.includes('2d') ? 'col-6' : 'col-12'">
                <b-form-checkbox
                    size="lg"
                    v-model="isR"
                    class="my-auto"
                    v-on:keyup.enter="setToPreviewVoucher()"
                ></b-form-checkbox>
                <b class="my-auto text-danger">{{ $store.state.language === "en" ? "Reverse ?" : "R မလား ?" }}</b>
            </div> -->

            <div class="col-12 mb-3 mt-0 pt-3 pr-0">
                <button
                    class="
                        btn btn-round btn-primary
                        px-5
                        float-right
                        form-group
                    "
                    ref="submitButton"
                    @click="setToPreviewVoucher()"
                >
                    {{
                        $store.state.language === "en"
                            ? "Add to Voucher"
                            : "ပေါင်းထည့်မည်"
                    }}
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import TwoDSelect from './TwoDSelect.vue';

export default {
  components: { TwoDSelect },
  props: ['type', 'allNumbers', 'drawTime'],
  data() {
    return {
      isR: true,
      numbers: {},
      // drawOptions: ['10:45', '12:00', '2:45', '4:30'],
      // allNumbers:
      //           this.type === 'twod'
      //             ? this.$store.state.twod_numbers
      //             : this.$store.state.threed_numbers,
      selected_data: {},
      errors: {},
      // drawTime: null,
      interval: null
    };
  },
  watch: {
    'selected_data.number': function () {
      if (this.selected_data.number) {
        this.focusOnMoneyInputBox();
      }
    },
    type() {
      this.allNumbers = this.type === 'twod'
        ? this.$store.state.twod_numbers
        : this.$store.state.threed_numbers;
    },
  },
  methods: {
    drawOptionGenerator(){
        const currentTime = new Date();

        const morning_mid = new Date();
        morning_mid.setHours(10, 40);

        const morning = new Date();
        morning.setHours(11, 55);

        const evening_mid = new Date();
        evening_mid.setHours(14, 40);

        const evening = new Date();
        evening.setHours(16, 25);

        if(currentTime < morning_mid){
          console.log('morning mid');
            return [
                {
                    data: '10:45',
                    label: '10:45 AM',
                },
                {
                    data: '12:00',
                    label: '12:00 AM',
                },
                {
                    data: '2:45',
                    label: '2:45 PM',
                },
                {
                    data: '4:30',
                    label: '4:30 PM',
                }
            ]
        } else if(currentTime < morning){
          console.log('morning');
            return [
                {
                    data: '12:00',
                    label: '12:00 AM',
                },
                {
                    data: '2:45',
                    label: '2:45 PM',
                },
                {
                    data: '4:30',
                    label: '4:30 PM',
                }
            ]
        } else if(currentTime < evening_mid){
          console.log('evening mid');
            return [
                        {
                            data: '2:45',
                            label: '2:45 PM',
                        },
                        {
                            data: '4:30',
                            label: '4:30 PM',
                        }
                    ]
        } else if(currentTime < evening)   {
          console.log('evening');
          return [
          
              {
                  data: '4:30',
                  label: '4:30 PM',
              }
          ]}
    },
    reverseString(str) {
      return str.split('').reverse().join('');
    },
    focusOnMoneyInputBox() {
      this.$refs.amount.focus();
    },
    focusOnSelectBox() {
      const inputs = document.getElementsByClassName('vs__search');
      console.log('inputs', inputs)
      for (let i = 0; i < inputs.length; i += 1) {
        inputs[i].focus();
      }
    },
    setToPreviewVoucher() {
      this.errors = {};
      if (!this.selected_data.number) {
        this.errors.number = this.$store.state.language === 'en'
          ? 'Please pick a number'
          : 'ဂဏန်းတစ်ခုရွေးချယ်ပါ။';
        return false;
      }
      if (!this.selected_data.amount) {
        this.errors.amount = this.$store.state.language === 'en'
          ? 'Please enter amount'
          : 'ငွေပမာဏထည့်ပေးပါ။';
        return false;
      }
      if (this.selected_data.amount < 100) {
        this.errors.amount = this.$store.state.language === 'en'
          ? 'Please enter at least 100 MMK'
          : 'ငွေပမာဏအနည်းဆုံး ၁၀၀ ထည့်ပေးပါ။';
        return false;
      }
      if (!this.drawTime && this.$route.path.includes('2d')) {
        this.errors.drawError = this.$store.state.language === 'en'
          ? 'Please select one draw option.'
          : 'ကျေးဇူးပြု၍ ပေါက်ဂဏန်းထွက်ချိန်တစ်ခုကို ရွေးချယ်ပေးပါ ။';
        return false;
      }
      const findDiffDraw = this.$store.state.twod_slip_data.find(data => data.draw !== this.drawTime)
      if (findDiffDraw) {
        alert(this.$store.state.language === 'en'
          ? 'Please add same draw time.'
          : 'ကျေးဇူးပြု၍ တူညီသော ပေါက်ဂဏန်းထွက်ချိန်ကို ရွေးချယ်ပါ ။'); 
        return false;
      }
      
      this.selectNumber(this.selected_data.number);
      this.setAmount(this.selected_data.amount);
      this.$emit('numbers', this.numbers);
      this.selected_data = {};
      this.focusOnSelectBox();
      return true;
    },
    setAmount(amount) {
      // const currentHour = new Date().getHours();
      // const draw = currentHour < 12 ? '12:00' : '4:30';
      for (let i = 0; i < this.numbers.length; i += 1) {
        this.numbers[i].solo_amount = amount;
        this.numbers[i].draw = this.drawTime;
      }
    },
    threedR(number) {
      this.numbers = this.allNumbers.filter((el) => {
        const threedArray = number
          .toString(10)
          .split('')
          .map((t) => t);
        const firstNum = threedArray[0];
        const secondNum = threedArray[1];
        const thirdNum = threedArray[2];
        /* eslint-disable */
            return (
              el.number == firstNum + thirdNum + secondNum
            || el.number == secondNum + firstNum + thirdNum
            || el.number == firstNum + secondNum + thirdNum
            || el.number == secondNum + thirdNum + firstNum
            || el.number == thirdNum + firstNum + secondNum
            || el.number == thirdNum + secondNum + firstNum
            );
          });
    },
    selectNumber(number) {
      if (this.isR && this.type === 'twod') {
        let reverseNumber = null;
        if (number.toString().length !== 1) {
          reverseNumber = parseInt(
            this.reverseString(number.toString()),
            10,
          );
        } else {
          reverseNumber = number * 10;
        }

        this.numbers = this.allNumbers.filter(
          (num) => parseInt(num.number, 10) === parseInt(number, 10)
                        || parseInt(num.number, 10) === reverseNumber,
        );
      } else if (this.isR && this.type !== 'twod') {
        this.threedR(number);
      } else {
        this.numbers = this.allNumbers.filter(
          (num) => num.number.toString() === number.toString(),
        );
      }
    },
  },
  destroyed() {
    clearInterval(this.interval);
  },
  unmounted() {
    clearInterval(this.interval);
  },
  mounted(){
    this.interval = setInterval(() => this.drawOptionGenerator(), 3000);
  }
};
</script>
<style scoped>
@media (max-width: 768px) {
    /* .number-card-body {
        padding: 20px;
    } */
}
</style>
