<template>
    <div class="bg-transparent px-3 pt-5 h-100vh">
        <div class="d-flex">
            <div class="col-4"></div>
            <div class="col-4">
                <div
                    class="w-100 mt-4"
                    style="border: 2px solid #191919; border-radius: 5px"
                >
                    <table class="table table-borderless">
                        <thead>
                            <tr>
                                <th
                                    style="
                                        font-size: 1.5rem !important;
                                        color: #d1b000;
                                    "
                                >
                                    ထီပေါက်စဥ်
                                </th>
                                <th
                                    style="
                                        font-size: 1.5rem !important;
                                        color: #d1b000;
                                    "
                                >
                                    မနက်
                                </th>
                                <th
                                    style="
                                        font-size: 1.5rem !important;
                                        color: #d1b000;
                                    "
                                >
                                    ညနေ
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(twodHistory, index) in twoDHistories"
                                :key="index"
                            >
                                <th
                                    style="
                                        font-size: 1.5rem !important;
                                        color: black;
                                    "
                                >
                                    {{ twodHistory.draw_date }}
                                </th>
                                <td
                                    style="
                                        font-size: 1.5rem !important;
                                        color: black;
                                    "
                                >
                                    {{ twodHistory.morning_result }}
                                </td>
                                <td
                                    style="
                                        font-size: 1.5rem !important;
                                        color: black;
                                    "
                                >
                                    {{ twodHistory.evening_result }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-4"></div>
        </div>
    </div>
</template>
<script>
export default {
  data() {
    return {
      twoDHistories: {},
    };
  },
  methods: {
    async fetchTwoThreeDHistories() {
      try {
        const twoDres = await axios.get('/twod_history');
        this.twoDHistories = twoDres.data.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.fetchTwoThreeDHistories();
  },
};
</script>
