<template>
  <div
    class="container-fluid"
    style="
      user-select: none;
      background-image: url('https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/519c389b-b823-4299-e2ea-46ea06e70a00/public'); 
      background-size: cover; padding-bottom: 15rem; background-attachment: fixed;
      padding-top: 50px; 
      height: 100vh;
      overflow-y: scroll;
      font-family: 'Roboto';
    "
  >
    <div class="row bg-transparent">
      <div class="col-md-6 col-sm-12 bg-transparent">
        <div class="col-12 mt-5 d-flex bg-transparent">
          <img
            style="width: 100%;"
            src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/4b769ecc-eedf-44a8-64f8-d1350a014400/public"
          />
        </div>
        <div class="left" style="margin: 0 0 0 5rem">
          
          <div class="col-12 bg-transparent">
            <img style="width: 100%;" src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/c6e7c34f-36b3-412f-3a2c-112bc689a900/public" alt="" />
          </div>
          <div class="button pt-2 d-flex justify-content-center flex-wrap">
            <a href="https://backapi.yy24.bet/api/app/download/android" download="yy24.apk" style="margin-top: -5px;">
              <img style="height: auto; width: 150px;" src="https://cdn.gold549.com/build/assets/img/brand/android-download.png" alt="" />
            </a>
            <a href="htthttps://backapi.yy24.bet/api/app/download/ios" download="yy24.mobileconfig">
              <img style="height: auto; width: 150px;" src="https://cdn.gold549.com/build/assets/img/brand/ios-download.png" alt="" />
            </a>
            <!-- <img @click="isAndroidGuide = true" style="height: 55px; width: 150px; margin-left: -10px; margin-top: 10px;" src="https://cdn.gold549.com/build/assets/img/brand/android.png" alt=""/>
            <img @click="isIosGuide = true" style="height: 54px; width: 150px; margin-top: -2px;  margin-top: 7px;" src="https://cdn.gold549.com/build/assets/img/brand/ios.png" alt="" /> -->
          </div>
        </div>
      </div>
    </div>
    <!-- <hr> -->
    <div class="col-12">
        <img style="width: 100%; z-index: 1000; position: relative" src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/d3756fd0-5f3c-4dc1-47e2-4bde5708ca00/public" alt="">
      </div>
    <div class="bg-transparent col-12 p-0 m-0">
      <img style="width: 100%; height: 100%;" src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/0db85ce6-b0cf-415c-2f97-e27f6e486900/public" alt="" />
    </div>
    
    <div style="margin-top: -20rem; z-index: 1000;">

      
        <div class="row justify-content-center mt-4">
          <div class="col-4 mx-2 my-3">
            <a href="https://heylink.me/Yy47/">
              <img src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/1a006526-2c40-4c58-a0bf-d6985f3f6000/public" style="width: 100%; height: 100%; background: #EEEEEE; border-radius: 10px;" alt="" />
            </a>
          </div>
          <!-- <div class="col-4 mx-2 my-3">
            <a href="viber://contact?number=%2B959770130747">
              <img src="https://cdn.gold549.com/build/assets/img/brand/agent_1.png" style="width: 100%; height: 100%; background: #EEEEEE; border-radius: 10px;" alt="" />
            </a>
          </div>
          <div class="col-4 mx-2 my-3">
            <a href="viber://contact?number=%2B959751771069">
              <img src="https://cdn.gold549.com/build/assets/img/brand/agent_2.png" style="width: 100%; height: 100%; background: #EEEEEE; border-radius: 10px;" alt="" />
            </a>
          </div>
          <div class="col-4 mx-2 my-3">
            <a href="viber://contact?number=%2B959269713231">
              <img src="https://cdn.gold549.com/build/assets/img/brand/agent_3.png" style="width: 100%; height: 100%; background: #EEEEEE; border-radius: 10px;" alt="" />
            </a>
          </div> -->
        </div>  
    </div>
    <div class="row d-flex main-footer" style="margin-top: 6rem !important">
      <p
        class="mx-auto text-white text-sm"
        style="text-align: center; font-weight: bold"
      >
        @ YY24 2024 Renamed <br />
        Privacy Teams FAQ
      </p>
    </div>
        <van-overlay :show="isAndroidGuide" @click="isAndroidGuide = false">
            <div class="wrapper" @click.stop>
              <img src="https://cdn.gold549.com/build/assets/img/guide/android_guide.jpg" alt="" style="width: 100%; height: 40vh; border-radius: 5px;">
              <div class="d-flex justify-content-center mt-4">
                <button @click="isAndroidGuide = false" class="btn btn-danger mx-auto">CLOSE</button>
              </div>
            </div>
        </van-overlay>
        <van-overlay :show="isIosGuide" @click="isIosGuide = false">
            <div class="wrapper" @click.stop>
              <img src="https://cdn.gold549.com/build/assets/img/guide/ios_guide_1.jpg" alt="" style="width: 100%; height: 25vh; border-radius: 5px;">
              <img src="https://cdn.gold549.com/build/assets/img/guide/ios_guide_2.jpg" alt="" style="width: 100%; height: 25vh; border-radius: 5px;">
              <img src="https://cdn.gold549.com/build/assets/img/guide/ios_guide_3.jpg" alt="" style="width: 100%; height: 25vh; border-radius: 5px;">
              <div class="d-flex justify-content-center mt-4">
                <button @click="isIosGuide = false" class="btn btn-danger mx-auto">CLOSE</button>
              </div>
            </div>
        </van-overlay>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isAndroidGuide: false,
      isIosGuide: false,
    };
  },
  methods: {
    guideShow(type) {
      if (type === 'android') {
        $('#androidModal').modal('show');
      } else {
        $('#iosModal').modal('show');
      }
    },
  },
  mounted() {},
};
</script>
<style scoped>
/* .main-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
} */
.main-footer {
    padding: 10px !important;
}
    
.text-img {
  width: 150px;
  height: 150px;
}
.phone-img {
  width: 140px;
  height: 270px;
}
h5 {
  color: #fff;
}
.p1 {
  font-size: 50px;
  color: #fff;
}
.img-cont {
  width: 30rem;
}
.heading-text {
  font-size: 32px !important;
}
.para-text {
  font-size: 18px !important;
}

@media (max-width: 786px) {
  .text-img {
    width: 130px !important;
    height: 90px !important;
  }
  .phone-img {
    width: 100px !important;
    height: 180px !important;
  }
  .img-cont {
    width: 15rem;
  }
  .main-footer {
    margin-top: 0rem !important;
    padding-top: 3.5rem !important;
    /* padding-bottom: 1rem !important; */
  }
  .right-img-container {
    padding-top: 4rem !important;
  }
  .left {
    margin-left: 0.4rem !important;
  }
  .para-text {
    font-size: 16px !important;
    font-weight: normal !important;
  }
  .btn-md {
    padding: 4px 20px;
    font-size: 14px;
    margin-top: 1rem;
    margin-left: 0.3rem;
    margin-right: 0.3rem;
  }
  .logo-img {
    margin: auto !important;
    width: 120px !important;
    height: 120px !important;
  }
  .logo {
    margin-top: 1rem !important;
  }
  .heading-text {
    font-size: 25px !important;
  }
}
</style>
