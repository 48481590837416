<template>
    <div>
        <!-- Page content -->
        <div class="ml-auto mr-auto">
            <div class="card card-upgrade mb-0">
                <div
                    class="
                        card-header
                        text-center
                        font-weight-bold
                        border-bottom-0
                    "
                >
                    {{
                        $store.state.language === "en" ? "Betslip" : "ဘောက်ချာ"
                    }}
                </div>
                <div class="card-body pt-0 px-1 text-sm">
                    <div class="mb-3 d-flex justify-content-between">
                        <div>
                            <div>
                                Draw :
                                <b>{{
                                    datas.length > 0 ? datas[0].draw_date : ""
                                }}</b>
                            </div>
                            <div>
                                {{
                                    $store.state.language === "en"
                                        ? "Slip id"
                                        : "ဘောက်ချာနံပါတ်"
                                }}
                                -
                                <b>{{ slipId }}</b>
                            </div>
                            <div>
                                {{
                                    $store.state.language === "en"
                                        ? "Type"
                                        : "Type"
                                }}
                                -
                                <b class="text-capitalize">{{ type }}</b>
                            </div>

                            <div v-for="(comm, commIndex) in commInfo" :key="commIndex" v-show="commInfo.length > 0">
                                {{comm['name']}}
                                -
                                <b class="text-capitalize">{{ comm['value'] }} MMK</b>
                            </div>
                        </div>

                        <div>
                            <div>
                                {{
                                    $store.state.language === "en"
                                        ? "Bet Time"
                                        : "ထိုးချိန်"
                                }}
                                : <b>{{ currentTime }} </b>
                            </div>
                            <div>
                                {{
                                    $store.state.language === "en"
                                        ? "Boards"
                                        : "အရေအတွတ်"
                                }}
                                :
                                <b>{{ datas.length > 0 ? datas.length : 0 }}</b>
                            </div>
                            <div>
                                {{
                                    $store.state.language === "en"
                                        ? "Total"
                                        : "စုစုပေါင်းငွေ"
                                }}
                                :
                                <b>
                                    {{ onlyTotalAmount() }}
                                    {{
                                        $store.state.language === "en"
                                            ? "MMK"
                                            : "ကျပ်"
                                    }}</b
                                >
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table style="width: 100%">
                            <thead>
                                <tr
                                    style="
                                        border-bottom: 1px solid
                                            #rgb(92, 95, 147);
                                    "
                                >
                                    <th class="text-center text-sm">
                                        {{
                                            $store.state.language === "en"
                                                ? "Game"
                                                : "Game"
                                        }}
                                    </th>
                                    <th class="text-center text-sm">
                                        {{
                                            $store.state.language === "en"
                                                ? "Selected"
                                                : "Selected"
                                        }}
                                    </th>
                                    <th class="text-center text-sm" v-if="isHistory">
                                        {{
                                            $store.state.language === "en"
                                                ? "Result"
                                                : "Result"
                                        }}
                                    </th>
                                    <th
                                        class="text-center text-sm"
                                        v-if="type === 'body'"
                                    >
                                        {{
                                            $store.state.language === "en"
                                                ? "Amount"
                                                : "Amount"
                                        }}
                                    </th>
                                    <th v-if="isHistory"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(data, index) in datas" :key="index">
                                    <td class="text-center text-xs pb-2">
                                        <div v-if="isHistory && data.league" class="d-flex justify-content-center mt-1"> 
                                            <span style="font-size: 0.65rem !important; color: #2359ed">
                                                {{data.league}}
                                            </span>
                                            <span style="font-size: 0.65rem !important; color: #2359ed">
                                                &nbsp; - &nbsp;{{ matchTimeOnly(data.start_time)}}
                                            </span>
                                        </div>
                                        <div v-if="isHistory">
                                            <!-- {{ firstMatchNo(data) }}.{{
                                                data.first_team
                                            }} -->
                                            {{
                                                $store.state.language === "en"
                                                    ? data.first_team_en
                                                    : data.first_team
                                            }}
                                            <span class="text-danger"
                                                >{{
                                                    data.first_team ===
                                                        data.kyayed_team &&
                                                    !data.goal_up_down
                                                        ? `&nbsp;(${
                                                              data.kyay === "~"
                                                                  ? "=="
                                                                  : data.kyay
                                                          })&nbsp;`
                                                        : ""
                                                }}{{
                                                    data.goal_up_down
                                                        ? `&nbsp;&nbsp;(GP${data.goal_kyay})&nbsp;`
                                                        : ""
                                                }}</span
                                            >{{ data.goal_up_down ? "" : "-" }}

                                            <!-- {{ secondMatchNo(data) }}.{{
                                                data.second_team
                                            }} -->
                                            {{
                                                $store.state.language === "en"
                                                    ? data.second_team_en
                                                    : data.second_team
                                            }}
                                            <span class="text-danger">{{
                                                data.second_team ===
                                                    data.kyayed_team &&
                                                !data.goal_up_down
                                                    ? `&nbsp;(${
                                                          data.kyay === "~"
                                                              ? "=="
                                                              : data.kyay
                                                      })&nbsp;`
                                                    : ""
                                            }}</span>
                                        </div>

                                        <div v-else>
                                            <!-- {{ firstMatchNo(data) }}.{{
                                                data.first_team
                                            }} -->
                                            {{
                                                $store.state.language === "en"
                                                    ? data.first_team_en
                                                    : data.first_team
                                            }}
                                            <span class="text-danger"
                                                >{{
                                                    data.first_team ===
                                                        data.kyayed_team &&
                                                    !data.goal_up_down
                                                        ? `&nbsp;(${
                                                              type === "maung"
                                                                  ? data.maung_kyay
                                                                  : data.body_kyay ===
                                                                    "~"
                                                                  ? "=="
                                                                  : type ===
                                                                    "maung"
                                                                  ? data.maung_kyay
                                                                  : data.body_kyay
                                                          })&nbsp;`
                                                        : ""
                                                }}{{
                                                    data.goal_up_down
                                                        ? `&nbsp;&nbsp;(GP
                                                        ${
                                                            getFBOrder.type ===
                                                            "maung"
                                                                ? data.maung_goal_kyay
                                                                : data.body_goal_kyay
                                                        }

                                                )&nbsp;`
                                                        : ""
                                                }}</span
                                            >

                                            {{ data.goal_up_down ? "" : "-" }}

                                            <!-- {{ secondMatchNo(data) }}.{{
                                                data.second_team
                                            }} -->
                                            {{
                                                $store.state.language === "en"
                                                    ? data.second_team_en
                                                    : data.second_team
                                            }}
                                            <span class="text-danger">{{
                                                data.second_team ===
                                                    data.kyayed_team &&
                                                !data.goal_up_down
                                                    ? `&nbsp;(${
                                                          type === "maung"
                                                              ? data.maung_kyay
                                                              : data.body_kyay ===
                                                                "~"
                                                              ? "=="
                                                              : type === "maung"
                                                              ? data.maung_kyay
                                                              : data.body_kyay
                                                      })&nbsp;`
                                                    : ""
                                            }}</span>
                                        </div>
                                    </td>
                                    <td class="text-center text-xs" :class="data.league ? 'pt-3' : ''">
                                        <div>
                                            <span v-if="!data.goal_up_down && !data.sone_ma">
                                                {{
                                                    $store.state.language === "en"
                                                        ? data.selected_team_en
                                                        : data.selected_team
                                                }}
                                            </span>

                                            <span
                                                class="text-danger text-nowrap"
                                                v-if="data.goal_up_down === 'up'"
                                                >
                                                {{
                                                    $store.state.language === "en" ? "Goal Up" : "ဂိုးပေါ်"
                                                }}
                                            </span>
                                            <span
                                                class="text-danger text-nowrap"
                                                v-if="data.goal_up_down === 'down'"
                                                >
                                                {{
                                                    $store.state.language === "en" ? "Goal Down" : "ဂိုးအောက်"
                                                }}
                                            </span>

                                            <!-- SONE MA  -->
                                            <span
                                                class="text-danger text-nowrap"
                                                v-if="data.sone_ma === 'sone'"
                                                >
                                                {{
                                                    $store.state.language === "en" ? "Even" : "စုံ"
                                                }}
                                            </span>
                                            <span
                                                class="text-danger text-nowrap"
                                                v-if="data.sone_ma === 'ma'"
                                                >
                                                {{
                                                    $store.state.language === "en" ? "Odd" : "မ"
                                                }}
                                            </span>
                                            <!-- END SONE MA  -->

                                        </div>
                                    </td>
                                    <td class="text-center text-xs"  :class="data.league ? 'pt-3' : ''">
                                        <div
                                            v-if="
                                                (data.first_team_result ||
                                                    data.first_team_result ===
                                                        0) &&
                                                (data.second_team_result ||
                                                    data.second_team_result ===
                                                        0)
                                            "
                                        >
                                            <span
                                                class="text-danger"
                                                v-if="data.is_cancel"
                                                >{{
                                                    $store.state.language ===
                                                    "en"
                                                        ? "(Cancel)"
                                                        : "(ပွဲပျက်)"
                                                }}</span
                                            >
                                            <span v-else>{{
                                                data.first_team_result +
                                                " : " +
                                                data.second_team_result
                                            }}</span>
                                        </div>
                                        <div v-else>
                                            {{ "-" }}
                                        </div>
                                    </td>
                                    <td
                                        class="text-center text-xs"
                                         :class="data.league ? 'pt-3' : ''"
                                        v-if="type === 'body'"
                                    >
                                        <div>
                                            {{ data.bet_amount }}
                                        </div>
                                    </td>
                                    <td
                                        class="text-center text-xs"
                                         :class="data.league ? 'pt-3' : ''"
                                        v-if="isHistory && is_result"
                                    >
                                        <div
                                            v-if="
                                                data.bingo_amount > 0 ||
                                                data.isBingo
                                            "
                                        >
                                            <i
                                                class="
                                                    fas
                                                    fa-check
                                                    text-success
                                                    pr-0
                                                "
                                            ></i>
                                            {{ data.bingo_amount }}
                                        </div>
                                        <div
                                            v-if="
                                                !data.bingo_amount > 0 &&
                                                !data.isBingo
                                            "
                                        >
                                            <i
                                                class="fas fa-times text-danger"
                                            ></i>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div
                        class="mb-3 d-flex justify-content-between"
                        v-if="isHistory && is_result"
                    >
                        <div></div>
                        <div>
                            <div>
                                {{
                                    $store.state.language === "en"
                                        ? "Total Bet Amount"
                                        : "စုစုပေါင်းအနိုင်ရငွေ"
                                }}
                                :
                                <b>
                                    {{ bingo_amount }}
                                    {{
                                        $store.state.language === "en"
                                            ? "MMK"
                                            : "ကျပ်"
                                    }}</b
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  props: [
    'datas',
    'slip',
    'currentTime',
    'slipId',
    'name',
    'amount',
    'type',
    'isHistory',
    'bingo_amount',
    'is_result',
    'commInfo',
  ],
  computed: {
    ...mapGetters(['getFBOrder']),
    currentDate() {
      return moment(new Date()).format('L');
    },
  },
  methods: {
    matchTimeOnly(time) {
        return moment(time).format("LT")
    },
    firstMatchNo(data) {
      return data?.match_no * 2 - 1;
    },
    secondMatchNo(data) {
      return data?.match_no * 2;
    },
    selectedMatchNo(data) {
      if (data?.selected_team === data?.first_team) return this.firstMatchNo(data);
      if (data?.selected_team === data?.second_team) return this.secondMatchNo(data);
      return true;
    },
    historyDate(time) {
      return moment(time).format('l');
    },
    onlyTotalAmount() {
      let total = 0;
      if (this.type === 'maung') {
        return parseInt(this.datas[0]?.bet_amount, 10);
      }
      for (let i = 0; i < this.datas.length; i += 1) {
        total += parseInt(this.datas[i].bet_amount, 10);
      }
      return total;
    },
  },
};
</script>
<style scoped>
.action-icons {
    font-size: 15px;
}
td i {
    font-size: 18px;
    padding: 5px;
    color: #2dce89;
    cursor: pointer;
}
td i:hover {
    color: grey;
}
td .fa-trash {
    color: red;
}
</style>
