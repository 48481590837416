<template>
    <v-select
        v-model="data[field]"
        :reduce="(account) => account.id"
        label="name"
        :options="accounts"
    >
      <template slot="option" slot-scope="option">
          <!-- <img style="width:20px; height: 20px; margin-left: -10px; border-radius: 3px;" :src="option.link" /> -->
          <!-- <span style="padding-top: 400px !important; padding-left: 10px !important">{{option.name }}</span> -->
          <span>{{option.name }}</span>
      </template>
    </v-select>
</template>
<script>
export default {
  props: ['data', 'field', 'accounts'],
};
</script>


