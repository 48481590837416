<template>
  <div class="col-md-4">
      <div
          class="modal fade"
          id="2d_group_box"
          tabindex="-1"
          role="dialog"
          aria-labelledby="modal-form"
          aria-hidden="true"
      >
          <div
              class="modal-dialog modal- modal-dialog-centered modal-sm"
              role="document"
          >
              <div class="modal-content">
                  <div class="modal-body">
                      <div class="group-container">
                          <div class="card px-4 pt-4" style="height: auto">
                              <div class="d-flex flex-row justify-content-between py-4" v-if="selected_data.number_select">
                                <span class="text-uppercase">Selected Number = <span class="text-danger text-lg">{{ selected_data.number_select }}</span></span>
                                <span class="text-sm d-flex flex-row" @click="selected_data.number_select = null">
                                  <i class="fas fa-redo my-auto mr-2 text-lg"></i>
                                  <div class="text-uppercase text-danger pt-1">
                                      {{
                                          $store.state.language === "en"
                                              ? "Reset Number"
                                              : "Reset Number"
                                      }}
                                  </div>
                                </span>
                              </div>
                              
                              <div class="d-flex flex-wrap">
                                  <a
                                      href="#"
                                      class="badge badge-pill badge-primary m-1"
                                      style="text-align: center"
                                      @click="getType('even_even')"
                                      >{{
                                          $store.state.language === "en" ? "Even Even" : "စုံစုံ"
                                      }}</a
                                  >
                                  <a
                                      href="#"
                                      class="badge badge-pill badge-primary m-1"
                                      style="text-align: center"
                                      @click="getType('odd_odd')"
                                      >{{ $store.state.language === "en" ? "Odd Odd" : "မမ" }}</a
                                  >
                                  <a
                                      href="#"
                                      class="badge badge-pill badge-primary m-1"
                                      @click="getType('even_odd')"
                                      >{{ $store.state.language === "en" ? "Even Odd" : "စုံမ" }}
                                  </a>
                                  <a
                                      href="#"
                                      class="badge badge-pill badge-primary m-1"
                                      @click="getType('odd_even')"
                                      >{{
                                          $store.state.language === "en" ? "Odd Even" : "မစုံ"
                                      }}</a
                                  >
                                  <a
                                      href="#"
                                      class="badge badge-pill badge-primary m-1"
                                      @click="getType('brother')"
                                      >{{
                                          $store.state.language === "en" ? "Brother" : "ညီကို"
                                      }}</a
                                  >

                                  <a
                                      href="#"
                                      class="badge badge-pill badge-primary m-1"
                                      @click="getType('constellation')"
                                      >{{
                                          $store.state.language === "en"
                                              ? "Constellation"
                                              : "နက္ခတ်"
                                      }}</a
                                  >
                                  <!-- <a
                                      href="#"
                                      class="badge badge-pill badge-primary m-1"
                                      @click="getType('front_serie')"
                                      >{{
                                          $store.state.language === "en"
                                              ? "Front Serie"
                                              : "ရှေ့စီးရီး"
                                      }}</a
                                  > -->
                                  <a
                                      href="#"
                                      class="badge badge-pill badge-primary m-1"
                                      @click="getType('back_serie')"
                                      >{{
                                          $store.state.language === "en"
                                              ? "Back Serie"
                                              : "နောက်စီးရီး"
                                      }}</a
                                  >
                                  <a
                                      href="#"
                                      class="badge badge-pill badge-primary m-1"
                                      @click="getType('only_one')"
                                      >{{
                                          $store.state.language === "en"
                                              ? "Only One"
                                              : "တစ်လုံးပါ"
                                      }}</a
                                  >
                                  <a
                                      href="#"
                                      class="badge badge-pill badge-primary m-1"
                                      @click="getType('double')"
                                      >{{ $store.state.language === "en" ? "Double" : "အပူး" }}</a
                                  >
                                  <a
                                      href="#"
                                      class="badge badge-pill badge-primary m-1"
                                      @click="getType('double_even')"
                                      >{{
                                          $store.state.language === "en"
                                              ? "Double(Even)"
                                              : "စုံပူး"
                                      }}</a
                                  >

                                  <a
                                      href="#"
                                      class="badge badge-pill badge-primary m-1"
                                      @click="getType('double_odd')"
                                      >{{
                                          $store.state.language === "en" ? "Double(Odd)" : "မအပူး"
                                      }}</a
                                  >
                                  <a
                                      href="#"
                                      class="badge badge-pill badge-primary m-1"
                                      @click="getType('brake')"
                                      >{{ $store.state.language === "en" ? "Brake" : "ဘရိတ်" }}</a
                                  >
                                  <a
                                      href="#"
                                      class="badge badge-pill badge-primary m-1"
                                      @click="getType('power')"
                                      >{{ $store.state.language === "en" ? "Power" : "ပါဝါ" }}</a
                                  >
                                  <a
                                      href="#"
                                      class="badge badge-pill badge-primary m-1"
                                      @click="getType('back_even')"
                                      >{{
                                          $store.state.language === "en"
                                              ? "Back Even"
                                              : "နောက်စုံ"
                                      }}</a
                                  >
                                  <a
                                      href="#"
                                      class="badge badge-pill badge-primary m-1"
                                      @click="getType('front_odd')"
                                      >{{
                                          $store.state.language === "en" ? "Front Odd" : "ရှေ့မ"
                                      }}</a
                                  >

                                  <a
                                      href="#"
                                      class="badge badge-pill badge-primary m-1"
                                      @click="getType('back_odd')"
                                      >{{
                                          $store.state.language === "en" ? "Back Odd" : "နောက်မ"
                                      }}</a
                                  >

                                  <a
                                      href="#"
                                      class="badge badge-pill badge-primary m-1"
                                      @click="getType('round_with_double')"
                                      >{{
                                          $store.state.language === "en"
                                              ? "Round With Double"
                                              : "အပြီးပေါက်အပူးပါ"
                                      }}</a
                                  >

                                  <a
                                      href="#"
                                      class="badge badge-pill badge-primary m-1"
                                      @click="getType('round_without_double')"
                                      >{{
                                          $store.state.language === "en"
                                              ? "Round Without Double"
                                              : "အပြီးပေါက်အပူးမပါ"
                                      }}</a
                                  >
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>

          <!-- === for front serie === -->
          <InputSelectDrawModalVue
              id_type="front_serie"
              :selected_data="selected_data"
              @type="getInputType"
          />

          <!-- === for back serie === -->
          <InputSelectDrawModalVue
              id_type="back_serie"
              :selected_data="selected_data"
              @type="getInputType"
          />

          <!-- === for back even === -->
          <InputSelectDrawModalVue
              id_type="back_even"
              :selected_data="selected_data"
              @type="getInputType"
          />

          <!-- === for front even === -->
          <InputSelectDrawModalVue
              id_type="front_even"
              :selected_data="selected_data"
              @type="getInputType"
          />

          <!-- === for front odd === -->
          <InputSelectDrawModalVue
              id_type="front_odd"
              :selected_data="selected_data"
              @type="getInputType"
          />

          <!-- === for back odd === -->
          <InputSelectDrawModalVue
              id_type="back_odd"
              :selected_data="selected_data"
              @type="getInputType"
          />

          <!-- === only one === -->
          <InputSelectDrawModalVue
              id_type="only_one"
              :selected_data="selected_data"
              @type="getInputType"
          />

          <!-- === brake === -->
          <InputSelectDrawModalVue
              id_type="brake"
              :selected_data="selected_data"
              @type="getInputType"
          />

          <!-- === for round_without_double === -->
          <InputSelectDrawModalVue
              id_type="round_with_double"
              modalType="round"
              :selected_data="selected_data"
              @type="getInputType"
          />

          <!-- === for round_without_double === -->
          <InputSelectDrawModalVue
              id_type="round_without_double"
              modalType="round"
              :selected_data="selected_data"
              @type="getInputType"
          />
      </div>
  </div>
</template>
<script>
import InputSelectDrawModalVue from './InputSelectDrawModal.vue';

export default {
props: {
  onMyEvent: Function,
  drawTime: String,
},
components: { InputSelectDrawModalVue },
data() {
  return {
    selected_data: {
      number_select: null
    },
    numbers: [],
    allNumbers: [],
  };
},
methods: {
  getInputType(type) {
    // this.selected_data = type.selected_data;
    this.selectNumbers(type.id_type);

    for (let i = 0; i < this.numbers.length; i += 1) {
      this.numbers[i].isSelect = true;
    }

    this.$emit('numbers', this.numbers);
  },
  getType(type) {
    if(!this.selected_data.number_select) {
        $('#'+type).modal('show');
      }
    if(this.selected_data.number_select && this.selected_data.number_select.length > 1 && type !== 'round_with_double' && type !== 'round_without_double') {
      $('#'+type).modal('show');
    }
    this.selectNumbers(type);

    for (let i = 0; i < this.numbers.length; i += 1) {
      this.numbers[i].isSelect = true;
    }

    this.$emit('numbers', this.numbers);
  },
  createDateWithTimeZone() {
    const moment = require('moment-timezone');
    const dateInTimeZone = moment.tz(new Date(), 'Asia/Rangoon');
    // Extract individual components from moment object
    const year = dateInTimeZone.year();
    const month = dateInTimeZone.month();
    const day = dateInTimeZone.date();
    const hour = dateInTimeZone.hour();
    const minute = dateInTimeZone.minute();
    const second = dateInTimeZone.second();
    const millisecond = dateInTimeZone.millisecond();

    // Create a new Date object with the extracted components
    const newDateObject = new Date(year, month, day, hour, minute, second, millisecond);
    return newDateObject;
  },
  // setAmount(amount) {
  //   const currentHour = this.createDateWithTimeZone().getHours();
  //   const draw = currentHour < 12 ? '12:00' : '4:30';
  //   for (let i = 0; i < this.numbers.length; i += 1) {
  //     this.numbers[i].solo_amount = amount;
  //     this.numbers[i].draw = draw;
  //   }
  // },
  selectNumbers(type) {
  //   for (let i = 0; i < this.allNumbers.length; i += 1) {
  //     this.allNumbers[i].isSelect = false;
  //   }

    switch (type) {
      case 'even_even':
        this.numbers = this.allNumbers.filter(
          (el) => parseInt(el.number, 10) % 2 === 0
                          && Math.floor(parseInt(el.number, 10) / 10) % 2 === 0,
        );
        break;
      case 'odd_odd':
        this.numbers = this.allNumbers.filter(
          (el) => parseInt(el.number, 10) % 2 !== 0
                          && Math.floor(parseInt(el.number, 10) / 10) % 2 !== 0,
        );

        break;
      case 'even_odd':
        this.numbers = this.allNumbers.filter(
          (el) => parseInt(el.number, 10) % 2 !== 0
                          && Math.floor(parseInt(el.number, 10) / 10) % 2 === 0,
        );
        break;
      case 'odd_even':
        this.numbers = this.allNumbers.filter(
          (el) => el.number % 2 === 0
                          && Math.floor(parseInt(el.number, 10) / 10) % 2 !== 0,
        );

        break;
      case 'double':
        this.numbers = this.allNumbers.filter((el) => {
          const lastChar = el.number.toString().slice(-1);
          const lastDigit = parseInt(lastChar, 10);
          return (
            Math.floor(parseInt(el.number, 10) / 10)
                          === lastDigit
          );
        });

        break;
      case 'double_even':
        this.numbers = this.allNumbers.filter((el) => {
          const lastChar = el.number.toString().slice(-1);
          const lastDigit = parseInt(lastChar, 10);
          return (
            Math.floor(parseInt(el.number, 10) / 10) % 2
                              === 0
                          && Math.floor(parseInt(el.number, 10) / 10)
                              === lastDigit
          );
        });

        break;
      case 'double_odd':
        this.numbers = this.allNumbers.filter((el) => {
          const toText = el.number.toString().toString();
          const lastChar = toText.slice(-1);
          const lastDigit = parseInt(lastChar, 10);
          return (
            Math.floor(parseInt(el.number, 10) / 10) % 2
                              !== 0
                          && Math.floor(parseInt(el.number, 10) / 10)
                              === lastDigit
          );
        });

        break;
      case 'power':
        this.numbers = this.allNumbers.filter(
          (el) => parseInt(el.number, 10) === 5
                          || parseInt(el.number, 10) === 50
                          || parseInt(el.number, 10) === 16
                          || parseInt(el.number, 10) === 61
                          || parseInt(el.number, 10) === 27
                          || parseInt(el.number, 10) === 72
                          || parseInt(el.number, 10) === 38
                          || parseInt(el.number, 10) === 83
                          || parseInt(el.number, 10) === 49
                          || parseInt(el.number, 10) === 94,
        );

        break;
      case 'constellation':
        this.numbers = this.allNumbers.filter(
          (el) => parseInt(el.number, 10) === 96
                          || parseInt(el.number, 10) === 7
                          || parseInt(el.number, 10) === 70
                          || parseInt(el.number, 10) === 18
                          || parseInt(el.number, 10) === 81
                          || parseInt(el.number, 10) === 24
                          || parseInt(el.number, 10) === 42
                          || parseInt(el.number, 10) === 35
                          || parseInt(el.number, 10) === 53
                          || parseInt(el.number, 10) === 69,
        );

        break;
      case 'brother':
        this.numbers = this.allNumbers.filter(
          (el) => parseInt(el.number, 10) === 12
                          || parseInt(el.number, 10) === 23
                          || parseInt(el.number, 10) === 34
                          || parseInt(el.number, 10) === 45
                          || parseInt(el.number, 10) === 56
                          || parseInt(el.number, 10) === 67
                          || parseInt(el.number, 10) === 78
                          || parseInt(el.number, 10) === 89
                          || parseInt(el.number, 10) === 90
                          || parseInt(el.number, 10) === 9
                          || parseInt(el.number, 10) === 98
                          || parseInt(el.number, 10) === 87
                          || parseInt(el.number, 10) === 76
                          || parseInt(el.number, 10) === 65
                          || parseInt(el.number, 10) === 54
                          || parseInt(el.number, 10) === 43
                          || parseInt(el.number, 10) === 32
                          || parseInt(el.number, 10) === 21
                          || parseInt(el.number, 10) === 10
                          || parseInt(el.number, 10) === 1,
        );

        break;
      case 'front_serie':
        if (!this.selected_data.number_select) $('#front_serie').modal('show');
        else $('#front_serie').modal('hide');

        this.numbers = this.allNumbers.filter((el) => {
          if (
            this.selected_data.number_select === '0'
                          && typeof el.number === 'string'
          ) {
            return el.number;
          }
          return (
            Math.floor(el.number / 10)
                          === this.selected_data.number_select
          );
        });

        this.selected_data = {};
        break;

      case 'back_odd':
        this.numbers = this.allNumbers.filter((el) => {
          if (
            this.selected_data.number_select === '0'
                          && typeof el.number === 'string'
                          && parseInt(el.number, 10) % 2 !== 0
          ) {
            return el.number;
          }
          return (
            Math.floor(el.number / 10)
                              === this.selected_data.number_select
                          && el.number % 2 !== 0
          );
        });

        break;
      case 'back_even':
        this.numbers = this.allNumbers.filter((el) => {
          if (
            this.selected_data.number_select === '0'
                          && typeof el.number === 'string'
                          && parseInt(el.number, 10) % 2 === 0
          ) {
            return el.number;
          }
          return (
            Math.floor(el.number / 10)
                              === this.selected_data.number_select
                          && el.number % 2 === 0
          );
        });

        break;
      case 'back_serie':
        this.numbers = this.allNumbers.filter((el) => {
          const toText = el.number.toString();
          const lastChar = toText.slice(-1);
          const lastDigit = parseInt(lastChar, 10);
          return (
            parseInt(this.selected_data.number_select, 10)
                          === lastDigit
          );
        });

        break;
      case 'front_even':
        this.numbers = this.allNumbers.filter((el) => {
          const toText = el.number.toString();
          const lastChar = toText.slice(-1);
          const lastDigit = parseInt(lastChar, 10);
          return (
            parseInt(this.selected_data.number_select, 10)
                              === lastDigit
                          && Math.floor(parseInt(el.number, 10) / 10) % 2 === 0
          );
        });

        break;
      case 'front_odd':
        this.numbers = this.allNumbers.filter((el) => {
          const toText = el.number.toString();
          const lastChar = toText.slice(-1);
          const lastDigit = parseInt(lastChar, 10);
          return (
            parseInt(this.selected_data.number_select, 10)
                              === lastDigit
                          && Math.floor(el.number / 10) % 2 !== 0
          );
        });

        break;
      case 'only_one':
        this.numbers = this.allNumbers.filter((el) => el.number
          .toString()
          .includes(this.selected_data.number_select));

        break;

      case 'brake':
        if(!this.selected_data.number_select) {
          $("#brake").modal('show');
        }
        this.numbers = this.allNumbers.filter((el) => {
          const toText = el.number.toString();
          const lastChar = toText.slice(-1);
          const lastDigit = parseInt(lastChar, 10);
          const total = Math.floor(parseInt(el.number, 10) / 10)
                          + lastDigit;

          if (
            total.toString().length > 1
                          && this.selected_data.number_select === 1
          ) {
            const toTextTotal = total.toString();
            const lastCharTotal = toTextTotal.slice(-1);
            const lastDigitTotal = parseInt(lastCharTotal, 10);
            return (
              lastDigitTotal
                              === this.selected_data.number_select
            );
          }
          return total
            .toString()
            .includes(this.selected_data.number_select);
        });

        break;
      case 'round_with_double':
        this.numbers = this.allNumbers.filter((el) => {
          const newArray = this.selected_data.number_select
            .toString(10)
            .split('')
            .map((t) => t);

          const toText = el.number.toString();
          const lastChar = toText.slice(-1);
          let lastFilter = null;
          let firstFilter = null;

          for (let i = 0; i < newArray.length; i += 1) {
            if (newArray[i] === lastChar) {
              lastFilter = true;
            }
          }
          const firstChar = Math.floor(
            parseInt(el.number, 10) / 10,
          );
          for (let j = 0; j < newArray.length; j += 1) {
            if (newArray[j] === firstChar.toString()) {
              firstFilter = true;
            }
          }

          return firstFilter && lastFilter;
        });

        break;
      case 'round_without_double':
        this.numbers = this.allNumbers.filter((el) => {
          const newArray = this.selected_data.number_select
            .toString(10)
            .split('')
            .map((t) => t);

          const toText = el.number.toString();
          const lastChar = toText.slice(-1);
          let lastFilter = null;
          let firstFilter = null;

          // Math.floor(parseInt(el.number, 10) / 10)
          for (let i = 0; i < newArray.length; i += 1) {
            if (newArray[i] === lastChar) {
              lastFilter = true;
            }
          }
          const firstChar = Math.floor(
            parseInt(el.number, 10) / 10,
          );
          for (let j = 0; j < newArray.length; j += 1) {
            if (newArray[j] === firstChar.toString()) {
              firstFilter = true;
            }
          }

          return (
            firstFilter
                          && lastFilter
                          && firstChar.toString() !== lastChar
          );
        });

        break;
      default:
        break;
    }
  },
  async fetchNumbers() {
    try {
        const res = await axios.post('/twod_numbers', {
          limit: 'all',
          drawTime: this.drawTime
        },{
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              },
          });
        this.$store.commit('setTwodnumbers', res.data.data);
        console.log(res)
        this.allNumbers = res.data.data;
        this.numbers = res.data.data;
      } catch (error) {
        console.log(error);
      }
  },
},
mounted() {
  this.fetchNumbers();
  this.onMyEvent(() => {
    this.numbers = [];
  });
},
};
</script>
<style scoped>
.badge.badge-pill {
  padding: 10px;
  border: 1px solid transparent;
  color: black !important;
}
.badge-primary[href]:hover{
  background-color: black;
  color: white !important;
}
@media (max-width: 425px) {
}
</style>
