<template>
    <nav class="side-nav card">
        <div>
            <div>
                <!-- Nav items -->
                <ul class="navbar-nav-custom">
                    <li
                        class="nav-items"
                        :class="linkIsActive('/withdraw') ? 'active' : ''"
                    >
                        <div class="nav-link" @click="redirectLink('withdraw')">
                            <i class="ni ni-money-coins mr-3"></i>
                            <span class="nav-link-text">{{
                                $store.state.language === "en"
                                    ? "Withdraw"
                                    : "ငွေထုတ်"
                            }}</span>
                        </div>
                    </li>
                    <li
                        class="nav-items"
                        :class="linkIsActive('/deposit') ? 'active' : ''"
                    >
                        <div class="nav-link" @click="redirectLink('deposit')">
                            <i class="ni ni-credit-card mr-3"></i>
                            <span class="nav-link-text">{{
                                $store.state.language === "en"
                                    ? "Deposit"
                                    : "ငွေသွင်း"
                            }}</span>
                        </div>
                    </li>
                    <li
                        class="nav-items"
                        :class="linkIsActive('/history') ? 'active' : ''"
                    >
                        <div class="nav-link" @click="redirectLink('history')">
                            <i class="ni ni-folder-17 mr-3"></i>
                            <span class="nav-link-text">{{
                                $store.state.language === "en"
                                    ? "History"
                                    : "ငွေ မှတ်တမ်းများ"
                            }}</span>
                        </div>
                    </li>
                    <li
                        class="nav-items"
                        :class="
                            linkIsActive('/betslip-history') ? 'active' : ''
                        "
                    >
                        <div
                            class="nav-link"
                            @click="redirectLink('betslip-history')"
                        >
                            <i class="ni ni-single-copy-04 mr-3"></i>
                            <span class="nav-link-text">{{
                                $store.state.language === "en"
                                    ? "Betslip History"
                                    : "ဘောက်ချာများ"
                            }}</span>
                        </div>
                    </li>
                </ul>
                <!-- Divider -->
                <hr class="my-3" />
                <!-- Navigation -->
                <ul class="navbar-nav-custom mb-md-3">
                    <li class="nav-items">
                        <div
                            class="nav-link active active-pro"
                            @click="logout()"
                        >
                            <i
                                class="ni ni-lock-circle-open text-danger mr-3"
                            ></i>
                            <span class="nav-link-text text-danger">{{
                                $store.state.language === "en"
                                    ? "Log Out"
                                    : "ထွက်မည်"
                            }}</span>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>
<script>
import { Toast, Dialog } from 'vant';

export default {
  methods: {
    /* example link = '/settings' */
    linkIsActive(link) {
      const paths = Array.isArray(link) ? link : [link];
      const res = paths.some(
        (path) => this.$route.path.indexOf(path) === 0,
      );
      if (res) {
        return true;
      }
      return false;
    },
    redirectLink(link) {
      this.$router.push(`/${link}`).catch(() => {});
    },
    logout() {
      Dialog.confirm({
        title:
                    this.$store.state.language === 'en'
                      ? 'Are you sure to log out?'
                      : 'ထွက်မှာ သေချာပါသလား?',
        cancelButtonText:
                    this.$store.state.language === 'en'
                      ? 'Cancel'
                      : 'မထွက်တော့ပါ',
        confirmButtonText:
                    this.$store.state.language === 'en' ? 'Sure' : 'သေချာပြီ',
      })
        .then(() => {
          this.$auth.logout({
            redirect: '/home',
          });
          Toast.success(
            this.$store.state.language === 'en'
              ? 'Logged out!'
              : 'ထွက်ပြီးပြီ',
          );
        })
        .catch(() => {});
    },
  },
};
</script>
