<template>
  <div class="container-fluid p-2">
    <!-- <h3 class="text-center mt-2">
            Following is the phone number of MS
        </h3> -->
    <h3 class="text-center mb-3 mt-2 text-dark text-uppercase">Our Service is 24 Hour</h3>
    <div class="card mx-2" style="box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; border-radius: 10px;">
      <div class="card-body">
        <div class="account-container-wrapper d-flex pl-0 mt-2 py-2">
          <div>
            <img src="https://aubet77mmk.com/mobile/images/contact/base.png" style="width: 50px;" alt="">
          </div>
          <div class="col-12 text-left">
            <h2>
              Welcome To PG-BOSS Live Support!
            </h2>
            <span>24 hours online for you.</span>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex flex-wrap justify-content-center bg-white mx-2 py-3" style="box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; border-radius: 10px;">
      <div class="col-5 d-flex flex-column my-4">
        <img src="https://yoeyar.vip/build/assets/img/theme/telegram.png" class="mx-auto" style="width: 35px; height: 35px;"/>
        <h3 class="text-center pt-2">Telegram Official Chat Box</h3>
      </div>
      <div class="col-5 d-flex flex-column my-4">
        <img src="https://yoeyar.vip/build/assets/img/theme/telegram.png" class="mx-auto" style="width: 35px; height: 35px;"/>
        <h3 class="text-center pt-2">Telegram Group</h3>
      </div>
      <div class="col-5 d-flex flex-column my-4">
        <img src="https://yoeyar.vip/build/assets/img/theme/facebook.png" class="mx-auto" style="width: 35px; height: 35px;"/>
        <h3 class="text-center pt-2">Facebook Page</h3>
      </div>
      <div class="col-5 d-flex flex-column my-4">
        <img src="https://yoeyar.vip/build/assets/img/theme/viber.png" class="mx-auto" style="width: 35px; height: 35px;"/>
        <h3 class="text-center pt-2">Viber Official</h3>
      </div>
    </div>
    <div class="mx-2 px-3 py-4 my-4 bg-white" style="box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; border-radius: 10px;">
      <h3>About Us</h3>
      <span>
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Cupiditate ratione ea deleniti, natus quae veritatis sit laudantium dignissimos nam aut. Ea itaque laborum quod excepturi minima nostrum harum hic aliquid! Lorem ipsum dolor, sit amet consectetur adipisicing elit. Cupiditate ratione ea deleniti, natus quae veritatis sit laudantium dignissimos nam aut. Ea itaque laborum quod excepturi minima nostrum harum hic aliquid!
      </span>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      websiteInfo: [],
    };
  },
  methods: {
    ...mapActions(['fetchUser']),
    async fetchWebsiteData() {
      try {
        const res = await axios.get('/website-infos');
        this.websiteInfo = res.data.data[0];
      } catch (error) {
        console.log(error);
      }
    },
  },
  async mounted() {
    const authUser = await this.fetchUser();
    if (!authUser) {
      this.$router.push('/auth/login');
    }
    this.fetchWebsiteData();
  },
};
</script>
