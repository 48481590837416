<template>
    <div class="group-container">
        <div class="card m-0" style="min-height: 240px; height: auto">
            <div
                class="card-header font-weight-bold text-center border-bottom-0"
            >
                {{
                    $store.state.language === "en"
                        ? "Three-D Group"
                        : "အုပ်စုလိုက်ထိုးမည်"
                }}
            </div>
            <div class="d-flex flex-wrap px-4">
                <a
                    href="#"
                    class="badge badge-pill badge-primary m-1"
                    style="text-align: center"
                    data-toggle="modal"
                    data-target="#tri"
                    >{{
                        $store.state.language === "en"
                            ? "Tri"
                            : "သုံးလုံးထွိုင်"
                    }}</a
                >
                <a
                    href="#"
                    class="badge badge-pill badge-primary m-1"
                    style="text-align: center"
                    data-toggle="modal"
                    data-target="#tri_round"
                    >{{
                        $store.state.language === "en"
                            ? "Tri Round"
                            : "သုံးလုံး ပတ်လည်"
                    }}</a
                >
                <a
                    href="#"
                    class="badge badge-pill badge-primary m-1"
                    style="text-align: center"
                    data-toggle="modal"
                    data-target="#rest_tri_round"
                    >{{
                        $store.state.language === "en"
                            ? "Rest Tri Round"
                            : "သုံးလုံး ကျန်ပတ်လည်"
                    }}</a
                >
                <a
                    href="#"
                    class="badge badge-pill badge-primary m-1"
                    style="text-align: center"
                    data-toggle="modal"
                    data-target="#brake"
                    >{{
                        $store.state.language === "en"
                            ? "Brake"
                            : "သုံးလုံး ဘရိတ်"
                    }}</a
                >
                <a
                    href="#"
                    class="badge badge-pill badge-primary m-1"
                    style="text-align: center"
                    data-toggle="modal"
                    data-target="#front_serie"
                    >{{
                        $store.state.language === "en"
                            ? "Front Serie"
                            : "ရှေ့စီးရီး"
                    }}</a
                >
                <a
                    href="#"
                    class="badge badge-pill badge-primary m-1"
                    style="text-align: center"
                    data-toggle="modal"
                    data-target="#middle_serie"
                    >{{
                        $store.state.language === "en"
                            ? "Middle Serie"
                            : "အလယ်စီးရီး"
                    }}</a
                >
                <a
                    href="#"
                    class="badge badge-pill badge-primary m-1"
                    style="text-align: center"
                    data-toggle="modal"
                    data-target="#back_serie"
                    >{{
                        $store.state.language === "en"
                            ? "Back Serie"
                            : "နောက်စီးရီး"
                    }}</a
                >
            </div>

            <!-- === for tri === -->
            <InputSelectDrawModalVue
                id_type="tri"
                modalType="one"
                :selected_data="selected_data"
                @type="getType"
            />
            <!-- === for tri_round === -->
            <InputSelectDrawModalVue
                id_type="tri_round"
                modalType="round"
                :selected_data="selected_data"
                @type="getType"
            />
            <!-- === for rest_tri_round === -->
            <InputSelectDrawModalVue
                id_type="rest_tri_round"
                modalType="round"
                :selected_data="selected_data"
                @type="getType"
            />
            <!-- === for rest_tri_round === -->
            <InputSelectDrawModalVue
                id_type="front_serie"
                :selected_data="selected_data"
                @type="getType"
            />
            <!-- === for rest_tri_round === -->
            <InputSelectDrawModalVue
                id_type="middle_serie"
                :selected_data="selected_data"
                @type="getType"
            />
            <!-- === for rest_tri_round === -->
            <InputSelectDrawModalVue
                id_type="back_serie"
                :selected_data="selected_data"
                @type="getType"
            />
            <!-- === for brake === -->
            <InputSelectDrawModalVue
                id_type="brake"
                :selected_data="selected_data"
                @type="getType"
            />
        </div>
    </div>
</template>
<script>
import InputSelectDrawModalVue from './InputSelectDrawModal.vue';

export default {
  components: { InputSelectDrawModalVue },
  data() {
    return {
      selected_data: {},
      numbers: [],
      allNumbers: [],
    };
  },
  methods: {
    getType(value) {
      this.selectNumbers(value.id_type);
      this.setAmount(value.amount);
      this.$emit('numbers', this.numbers);
    },
    setAmount(amount) {
      const currentHour = new Date().getHours();
      const draw = currentHour < 12 ? '12:00' : '4:30';
      for (let i = 0; i < this.numbers.length; i += 1) {
        this.numbers[i].solo_amount = amount;
        this.numbers[i].draw = draw;
      }
    },
    selectNumbers(type) {
      switch (type) {
        case 'tri':
          this.numbers = this.allNumbers.filter((el) => {
            const threedArray = el.number
              .toString(10)
              .split('')
              .map((t) => t);
            const firstNum = threedArray[0];
            const secondNum = threedArray[1];
            const thirdNum = threedArray[2];
            return firstNum === secondNum && secondNum === thirdNum;
          });
          break;
        case 'tri_round':
          this.numbers = this.allNumbers.filter((el) => {
            const threedArray = this.selected_data.number_select
              .toString(10)
              .split('')
              .map((t) => t);
            const firstNum = threedArray[0];
            const secondNum = threedArray[1];
            const thirdNum = threedArray[2];
            /* eslint-disable */
            return (
              el.number == firstNum + thirdNum + secondNum
            || el.number == secondNum + firstNum + thirdNum
            || el.number == firstNum + secondNum + thirdNum
            || el.number == secondNum + thirdNum + firstNum
            || el.number == thirdNum + firstNum + secondNum
            || el.number == thirdNum + secondNum + firstNum
            );
          });
          break;
        case 'rest_tri_round':
          this.numbers = this.allNumbers.filter((el) => {
            const threedArray = this.selected_data.number_select
              .toString(10)
              .split('')
              .map((t) => t);
            const firstNum = threedArray[0];
            const secondNum = threedArray[1];
            const thirdNum = threedArray[2];
            /* eslint-disable */
            return (
              (el.number == firstNum + thirdNum + secondNum
            || el.number == secondNum + firstNum + thirdNum
            || el.number == firstNum + secondNum + thirdNum
            || el.number == secondNum + thirdNum + firstNum
            || el.number == thirdNum + firstNum + secondNum
            || el.number == thirdNum + secondNum + firstNum) && (this.selected_data.number_select.toString() !== el.number.toString())
            );
          });
          break;
        // case 'rest_tri_round':
        //   this.numbers = this.allNumbers.filter((el) => {
        //     const threedArray = this.selected_data.number_select
        //       .toString(10)
        //       .split('')
        //       .map((t) => t);
        //     const firstNum = threedArray[0];
        //     const secondNum = threedArray[1];
        //     const thirdNum = threedArray[2];
        //     return (
        //       el.number.toString(10).includes(firstNum)
        //                     && el.number.toString(10).includes(secondNum)
        //                     && el.number.toString(10).includes(thirdNum)
        //                     && this.selected_data.number_select.toString()
        //                         !== el.number.toString()
        //     );
        //   });
        //   break;
        case 'brake':
          this.numbers = this.allNumbers.filter((el) => {
            const threedArray = el.number
              .toString(10)
              .split('')
              .map((t) => t);
            let total = 0;
            for (let i = 0; i < threedArray.length; i += 1) {
              total += parseInt(threedArray[i], 10);
            }
            const totalArray = total.toString();
            const lastChar = totalArray.slice(-1);

            return (
              lastChar
                            === this.selected_data.number_select.toString()
            );
          });
          break;
        case 'front_serie':
          this.numbers = this.allNumbers.filter((el) => {
            const threedArray = el.number
              .toString(10)
              .split('')
              .map((t) => t);
            const front = threedArray[0];
            const second = threedArray[1];
            const third = threedArray[2];
            if (
              this.selected_data.number_select.toString()
                            === front.toString()
            ) {
              return (
                (second === '1' && third === '2')
                                || (second === '1' && third === '2')
                                || (second === '3' && third === '4')
                                || (second === '5' && third === '6')
                                || (second === '7' && third === '8')
                                || (second === '9' && third === '0')
                                || (second === '0' && third === '9')
                                || (second === '8' && third === '7')
                                || (second === '6' && third === '5')
                                || (second === '4' && third === '3')
                                || (second === '2' && third === '1')
              );
            }
            return false;
          });

          break;
        case 'middle_serie':
          this.numbers = this.allNumbers.filter((el) => {
            const threedArray = el.number
              .toString(10)
              .split('')
              .map((t) => t);
            const first = threedArray[0];
            const second = threedArray[1];
            const third = threedArray[2];
            if (
              this.selected_data.number_select.toString()
                            === second.toString()
            ) {
              return (
                (first === '1' && third === '2')
                                || (first === '1' && third === '2')
                                || (first === '3' && third === '4')
                                || (first === '5' && third === '6')
                                || (first === '7' && third === '8')
                                || (first === '9' && third === '0')
                                || (first === '0' && third === '9')
                                || (first === '8' && third === '7')
                                || (first === '6' && third === '5')
                                || (first === '4' && third === '3')
                                || (first === '2' && third === '1')
              );
            }
            return false;
          });

          break;
        case 'back_serie':
          this.numbers = this.allNumbers.filter((el) => {
            const threedArray = el.number
              .toString(10)
              .split('')
              .map((t) => t);
            const first = threedArray[0];
            const second = threedArray[1];
            const third = threedArray[2];
            if (
              this.selected_data.number_select.toString()
                            === third.toString()
            ) {
              return (
                (first === '1' && second === '2')
                                || (first === '1' && second === '2')
                                || (first === '3' && second === '4')
                                || (first === '5' && second === '6')
                                || (first === '7' && second === '8')
                                || (first === '9' && second === '0')
                                || (first === '0' && second === '9')
                                || (first === '8' && second === '7')
                                || (first === '6' && second === '5')
                                || (first === '4' && second === '3')
                                || (first === '2' && second === '1')
              );
            }
            return false;
          });

          break;
        default:
          break;
      }
    },
    async fetchNumbers() {
      try {
        const res = await axios.post('/threed_numbers', {
          limit: 'all',
        });
        this.numbers = res.data.data;
        this.allNumbers = res.data.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.fetchNumbers();
  },
};
</script>
<style scoped>
.badge.badge-pill {
    padding: 10px;
    border: 1px solid transparent;
}
@media (max-width: 425px) {
}
</style>
