<template>
    <div>
        <img v-if="!$store.state.isCardGame" @click="$router.push('/home')" src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/general/Hot%20Game%201%202.png" alt="" style="height: 30px; z-index: 1000000000; left: 10px; top: 30px; position: absolute;">
        <iframe name="iframe1" :srcdoc="isHttp ? null : $store.state.iframeURL" :src="isHttp ? $store.state.iframeURL : null" class="iframe-style bg-dark">
        </iframe>
    </div>
</template>
<script>

export default {
  async mounted() {
    if (!this.$store.state.iframeURL) {
      this.$router.push('/home');
    }
  },
  computed: {
    isHttp() {
      return this.$store.state.iframeURL.startsWith('http');
    }
  }
};
</script>
<style scoped>
.iframe-style {
    width: 100vh; 
    height: 100vh; 
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    z-index: 10000;
}
</style>
