<template>
  <div>
    <Sticky>
      <nav class="navbar navbar-horizontal navbar-expand-lg" style="background: #ffffff;">
        <div class="container-fluid">
          <div
            class="navbar-brand text-white"
            :class="
              $route.path !== '/home' ? 'd-flex justify-content-between' : ''
            "
          >
            <div
              class="d-flex flex-column banking-icons my-auto ml-1 mr-3"
              v-if="$route.path !== '/auth/login'"
            >
              <i
                class="fas fa-chevron-left mx-auto my-1 text-xl text-dark"
                @click="$playSound(); $router.go(-1)"
                v-if="$route.path !== '/home'"
              ></i>
            </div>
            <img
              src="/build/assets/img/brand/logo.png"
              alt=""
              @click="$playSound(); $router.push('/home')"
            />
            <!-- LOGO -->
          </div>
          <div class="d-flex">

            <!-- Refresh  -->
            <!-- <div
              class="navbar-brand text-white mx-3 d-flex flex-column"
              @click="$router.go()"
            >
              <i class="fas fa-redo mx-auto"></i>
              <div>
                {{ $store.state.language === 'en' ? 'Refresh' : 'Refresh' }}
              </div>
            </div> -->
            <div class="d-flex" v-if="$route.path !== '/auth/login' && authUser">
              
              <span class="my-auto pr-3" style="border-right: 1px solid gray;">
                <span class="text-sm text-uppercase">
                  {{ $t('setting.balance') }} :
                </span>
                <span class="text-dark text-md">
                  <b>{{ Number($store.state.authUser.amount).toLocaleString() }}</b> MMK
                </span>
              </span>
            </div>
            

            <!-- Language -->
            <div
              class="nav-link d-flex justify-content-between"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
                  <div id="navbar-language">
                    <img
                        :src="$t('images.lang-img')"
                        alt=""
                        class="mr-2"
                        style="width: 25px; height: 25px; border-radius: 50%;"
                      />
                  </div>
                </div>
                <div
                  class="dropdown-menu dropdown-menu-right"
                  aria-labelledby="navbar-language"
                >
                  <a class="dropdown-item" @click="setLangLocalstorage('en')"
                    ><img
                      src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/general/united-kingdom.png"
                      alt=""
                      class="mr-2"
                      style="width: 20px; height: 20px"
                    />
                    {{ $t("en") }}</a
                  >
                  <div class="dropdown-divider"></div>
                    <a class="dropdown-item" @click="setLangLocalstorage('mm')"
                      ><img
                        src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/general/myanmar.png"
                        alt=""
                        class="mr-2"
                        style="width: 20px; height: 20px"
                      />{{ $t("mm") }}</a
                    >
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" @click="setLangLocalstorage('cn')"
                      ><img
                        src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/general/china.png"
                        alt=""
                        class="mr-2"
                        style="width: 20px; height: 20px"
                      />{{ $t("cn") }}</a
                    >
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" @click="setLangLocalstorage('th')"
                      ><img
                        src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/general/thailand.png"
                        alt=""
                        class="mr-2"
                        style="width: 20px; height: 20px"
                      />{{ $t("th") }}</a
                    >

                  </div>
                </div>
        </div>
      </nav>
    </Sticky>
  </div>
</template>
<script>
import { Toast, Dialog, Sticky } from 'vant';
import { mapActions } from 'vuex';

export default {
  components: { Sticky },
  data() {
    return {
      // imageLink: `https://ui-avatars.com/api/?name=${this.$store.state.authUser.email}&background=0D8ABC&color=fff`,
      isLogged: false,
      authUser: null,
      alreadyGranted: true,
    };
  },
  methods: {
    ...mapActions(['logoutUser', 'fetchUser']),
    askNotificationPermission() {
        Notification.requestPermission().then(async (permission) => {
          if(permission === 'granted') {
            this.alreadyGranted = false;
           alert(
              this.$store.state.language === 'en'
                ? 'Notification permission granted!'
                : 'အသိပေးချက်အတည်ပြုပြီးပြီ'
            );
          } else {
            alert(
              this.$store.state.language === 'en'
                ? 'Notification permission denied!'
                : 'အသိပေးချက်အတည်ပြုမပြီးပြီ'
            );
          }
        });
  },
    logout() {
      Dialog.confirm({
        title:
          this.$store.state.language === 'en'
            ? 'Are you sure to log out?'
            : 'ထွက်မှာ သေချာပါသလား?',
        cancelButtonText:
          this.$store.state.language === 'en' ? 'Cancel' : 'မထွက်တော့ပါ',
        confirmButtonText:
          this.$store.state.language === 'en' ? 'Sure' : 'သေချာပြီ',
      })
        .then(async () => {
          await this.logoutUser();
          if(this.$route.path === '/home') {
            this.$router.go();
          } else {
            this.$router.push('/home');
            this.$router.go();
          }
          Toast.success(
            this.$store.state.language === 'en' ? 'Logged out!' : 'ထွက်ပြီးပြီ'
          );
        })
        .catch(() => {});
    },
    closeNavCollapse() {
      $('#navbar-default').collapse('hide');
    },
    setLangLocalstorage(language) {
      localStorage.setItem('language', language);
      this.$store.commit('setLanguage', language);
      this.$i18n.locale = language
      $('#navbar-default').collapse('hide');
    },
    redirectLink(link) {
      this.$router.push(`/${link}`).catch(() => {});
      $('#navbar-default').collapse('hide');
    },
    redirectRoute(path) {
      if (path === '2d') {
        this.$router.push('/user/2d');
      } else {
        this.$router.push('/user/3d');
      }
      return true;
    },
  },
  async mounted() {
    if(window.Notification.permission === 'granted') {
      this.alreadyGranted = false;
    }

    this.authUser = await this.fetchUser();
    if (this.authUser) {
      this.isLogged = true;
    }
  }
};
</script>
<style scoped>
/* CSS */
.login-btn {
  background: transparent;
  border: 1px solid #D02126;
  border-radius: 3px;
  padding: .3rem 1.5rem;
  color: #D02126;
}



.navbar {
  padding: 3px;
  padding-left: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
  /* border-bottom: 2px solid black; */
}
.navbar-horizontal .navbar-brand img {
  height: 40px;
}
.nav-link {
  cursor: pointer;
}
.navbar-brand.brand-text {
  font-size: 20px;
  color: red;
  margin-left: 0px;
  margin-right: 10px;
}
.navbar-brand {
  font-size: 16px;
  margin-left: 20px;
  margin-right: 20px;
  cursor: pointer;
}
.dropdown-item {
  cursor: pointer;
}
.bg-default {
  background-color: #02133E !important;
}
@media (max-width: 767.98px) {
  .navbar-brand {
    font-size: 14px;
    margin-left: 0px;
    margin-right: 0px;
  }
}
</style>
