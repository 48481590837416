<template>
  <div
    style="background-color: #C6001C; background-image: url('https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/c8f60d75-e83a-4e1e-8bbc-f6b38bfa7100/public'); min-height: 100vh; height: auto; background-size: cover; padding-bottom: 15rem; background-attachment: fixed;"
  >
    <div class="login-cont container" style="height: 80vh;">
      <div class="d-flex justify-content-center h-100">
        <div class="col-12 px-0 d-flex flex-column my-auto">
          <img
            class="mx-auto"
            src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/04e0ed3a-8be9-4f4f-54db-7d21cae5b500/public"
            alt=""
            @click="$router.push('/home')"
            style="height: 200px"
          />
          <!-- <div class="card border-0 mb-0 auth-card bg-transparent"> -->
            <div class="card-body px-2 d-flex mx-0 col-11 mx-auto" style="background: rgba(255, 255, 255, 0.25);border-radius: 16px;box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);backdrop-filter: blur(10px);-webkit-backdrop-filter: blur(10px);">
              <div class="my-auto col-12">
                <div class="text-center mb-4">
                  <div class="error text-center py-2" v-if="error">
                    <span class="mx-auto">
                      {{$t('auth.fail_message')}}
                    </span>
                    
                  </div>
                  </div>
                  <form role="form">
                  <small class="text-dark">{{ errors.name }}</small>
                  <div class="form-group mb-3">
                    <label style="color: white; font-weight: 550; letter-spacing: 0.5px;">{{$t('auth.username')}}</label>
                    <!-- <div
                      class="input-group input-group-merge input-group-alternative bg-transparent"
                    > -->
                      <input
                        class="form-control pl-2 px-4"
                        style="background-color: #FF2F2E; color: white; box-shadow: none; border-radius: 8px; border: none; font-size: 1.2rem;"
                        type="text"
                        v-model="name"
                        @keyup.enter="focusOnPasswordInputBox"
                      />
                    <!-- </div> -->
                  </div>
                  <small class="text-dark pt-3">{{ errors.password }}</small>
                  <div class="form-group" :class="errors.password ? '' : 'pt-3'">
                    <label style="color: white; font-weight: 550; letter-spacing: 0.5px;">{{$t('auth.password')}}</label>
                    <!-- <div
                      class="input-group input-group-merge input-group-alternative"
                    > -->
                    <div class="input-group">
                      <input
                        class="form-control pl-2 px-4"
                        style="background-color: #FF2F2E; color: white; box-shadow: none; border-radius: 8px; border: none; font-size: 1.2rem;"
                        type="password"
                        ref="password"
                        v-model="password"
                        @keyup.enter="loginWithPhone"
                      />
                      <i
                        class="far fa-eye showable-password text-white"
                        v-if="isShow"
                        @click="$playSound(); showablePassword()"
                      ></i>
                      <i
                        v-if="!isShow"
                        class="fas fa-eye-slash showable-password text-white"
                        @click="$playSound(); showablePassword()"
                      ></i>
                    </div>
                    <!-- </div> -->
                  </div>
                  <div class="text-center mt-4">
                    <button
                      type="button"
                      class="btn my-4"
                      style="background: white; color: #B40007; border-radius: 20px; width: 10rem; box-shadow: none;"
                      @click="$playSound(); loginWithPhone()"
                    >
                      <div v-if="!isSignin">
                        {{ $t('auth.login') }}
                      </div>

                      <Loading
                        color="#B40007"
                        v-if="isSignin"
                        size="24"
                        class="px-4 py-0"
                      />
                    </button>
                  </div>
                  </form>
              </div>
            </div>
          <!-- </div> -->
          <div class="row mt-3">
            <div class="col-4">
              <div
                class="modal fade"
                id="forgetPassword"
                tabindex="-1"
                role="dialog"
                aria-labelledby="modal-form"
                aria-hidden="true"
              >
                <div
                  class="modal-dialog modal- modal-dialog-centered"
                  role="document"
                >
                  <div class="modal-content">
                    <div class="modal-body p-5">
                      <b>
                        Please direct contact to customer service -
                        <span class="text-danger">09756477723</span>
                      </b>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast, Loading } from 'vant';
import { mapActions } from 'vuex';

export default {
  components: { Loading },
  data() {
    return {
      name: null,
      password: null,
      error: false,
      errors: {},
      isSignin: false,
      isShow: false,
    };
  },
  methods: {
    ...mapActions(['fetchUser']),
    showablePassword() {
      if (this.isShow) {
        this.$refs.password.type = 'password';
      } else {
        this.$refs.password.type = 'text';
      }
      this.isShow = !this.isShow;
    },
    focusOnPasswordInputBox() {
      this.$refs.password.focus();
    },
    /**
     * login with phone
     */
    async loginWithPhone() {
      this.isSignin = true;
      this.errors = {};
      if (!this.name) {
        this.errors.name = this.$t('auth.require_username');
        this.isSignin = false;
        return false;
      }
      if (!this.password) {
        this.errors.password = this.$t('auth.require_password');;
        this.isSignin = false;
        return false;
      }
      try {
        if (this.name === 'testuser') {
          window.open('https://about.myanmarsoccer.xyz', '_self');
        } else {
          const res = await this.axios.post(
            '/auth/login',
            {
              name: this.name,
              password: this.password,
              roles: 'normal',
              fcm: localStorage.getItem('fcm'),
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
              },
            }
          );
          localStorage.setItem('username', this.name);
          localStorage.setItem('password', this.password);
          localStorage.setItem('token', res.data.token);

          const currentTime = new Date();
          localStorage.setItem(
            'tokenExpire',
            new Date(currentTime.getTime() + 60 * 60 * 1000)
          );
          await this.fetchUser();
          this.$router.push('/home');
          // this.$router.go();
          // ..
          Toast.success(
            this.$store.state.language === 'en'
              ? 'Success!'
              : 'Success!'
          );
        }
      } catch (err) {
        this.error = true;
      }
      this.isSignin = false;
      return true;
    },
  },
  async mounted() {
    const authUser = await this.fetchUser();
    if (authUser && this.$store.state.authUser.roles === 'normal')
      this.$router.push('/home');
    const userName = localStorage.getItem('username');
    const password = localStorage.getItem('password');
    if (userName && password) {
      this.name = userName;
      this.password = password;
    }
  },
};
</script>

<style scoped>
.login-cont {
  padding-top: 3rem;
}

.showable-password {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
  z-index: 1000;
}

.new-account-text {
  cursor: pointer;
}

.error {
  margin-bottom: 13px;
  /* height: 40px; */
  display: flex;
  align-items: center;
  color: black;
  border: 1px solid black;
  border-radius: 8px;
  padding: 0px 15px;
  background: #ff000014;
  /* font-weight: bold; */
}

.auth-card {
  background: #eeecec;
  /* color: black */
}
</style>
